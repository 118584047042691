export default function Jadwal() {
    return {
        id: null,
        hari: null,
        jamMulai: null,
        jamSelesai: null,
        durationInMinute: null,
        tanggalMulai: null,
        tanggalSelesai: null,
        dokter: [],
        statusJadwalDokters: [],
        slotIndexBlocked: []
    };
}
