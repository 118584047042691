<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1098_39128)">
            <path
                d="M9.16647 13.2784C7.59641 13.0655 6.16576 12.2644 5.16384 11.037C4.16192 9.80956 3.66349 8.24746 3.76935 6.66658C3.87521 5.0857 4.57745 3.604 5.73407 2.52112C6.89069 1.43825 8.41538 0.835003 9.9998 0.833377C11.5862 0.831848 13.1137 1.43361 14.2729 2.51665C15.432 3.5997 16.1359 5.08299 16.2419 6.6658C16.3478 8.24862 15.848 9.81251 14.8436 11.0404C13.8392 12.2683 12.4055 13.0684 10.8331 13.2784V15H14.9998V16.6667H10.8331V20H9.16647V16.6667H4.9998V15H9.16647V13.2784ZM9.9998 11.6667C11.2154 11.6667 12.3812 11.1838 13.2407 10.3243C14.1002 9.46474 14.5831 8.29895 14.5831 7.08338C14.5831 5.8678 14.1002 4.70201 13.2407 3.84247C12.3812 2.98293 11.2154 2.50004 9.9998 2.50004C8.78422 2.50004 7.61844 2.98293 6.75889 3.84247C5.89935 4.70201 5.41647 5.8678 5.41647 7.08338C5.41647 8.29895 5.89935 9.46474 6.75889 10.3243C7.61844 11.1838 8.78422 11.6667 9.9998 11.6667Z"
                :fill="strokeColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1098_39128">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

<style></style>
