<template>
    <button
        :disabled="isDisable"
        @click.prevent="changeSort"
        class="sort-doctor"
        :style="isDisable ? 'background:#d9d9d9;' : ''"
    >
        <svg
            v-if="isAsc"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.1227 8.44358L14.4957 10.5001H12.8967L15.5802 2.50208H17.4312L20.1027 10.5001H18.4227L17.7942 8.44358H15.1227ZM17.4777 7.26608L16.4997 4.03058H16.4292L15.4512 7.26608H17.4777Z"
                fill="#23415B"
            />
            <path
                d="M19.4397 21H13.5417V19.9635L17.4102 14.3835V14.3025H13.6467V13.002H19.3242V14.0385L15.4737 19.6185V19.6995H19.4412V21H19.4397ZM6.74973 3.75C6.74973 3.55109 6.67071 3.36032 6.53006 3.21967C6.3894 3.07902 6.19864 3 5.99973 3C5.80081 3 5.61005 3.07902 5.4694 3.21967C5.32874 3.36032 5.24973 3.55109 5.24973 3.75V18.4395L3.53073 16.719C3.46099 16.6493 3.37821 16.594 3.2871 16.5562C3.19599 16.5185 3.09834 16.4991 2.99973 16.4991C2.90111 16.4991 2.80346 16.5185 2.71235 16.5562C2.62124 16.594 2.53846 16.6493 2.46873 16.719C2.39899 16.7887 2.34368 16.8715 2.30594 16.9626C2.2682 17.0537 2.24878 17.1514 2.24878 17.25C2.24878 17.3486 2.2682 17.4463 2.30594 17.5374C2.34368 17.6285 2.39899 17.7113 2.46873 17.781L5.46873 20.7795L5.47923 20.79C5.62004 20.9273 5.80941 21.0034 6.00608 21.0017C6.20275 21 6.39079 20.9207 6.52923 20.781L9.52923 17.781C9.67006 17.6404 9.74925 17.4496 9.74939 17.2505C9.74953 17.0515 9.67061 16.8606 9.52998 16.7197C9.38935 16.5789 9.19853 16.4997 8.99951 16.4996C8.80048 16.4994 8.60956 16.5784 8.46873 16.719L6.74973 18.4395V3.75Z"
                fill="#23415B"
            />
        </svg>

        <svg
            v-else
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.1227 8.44358L14.4957 10.5001H12.8967L15.5802 2.50208H17.4312L20.1027 10.5001H18.4227L17.7942 8.44358H15.1227ZM17.4777 7.26608L16.4997 4.03058H16.4292L15.4512 7.26608H17.4777Z"
                fill="#23415B"
            />
            <path
                d="M19.44 21.0002H13.542V19.9637L17.4105 14.3837V14.3027H13.647V13.0022H19.3245V14.0387L15.474 19.6187V19.6997H19.4415V21.0002H19.44ZM6.74997 20.2502C6.74997 20.4491 6.67095 20.6398 6.5303 20.7805C6.38965 20.9211 6.19888 21.0002 5.99997 21.0002C5.80106 21.0002 5.61029 20.9211 5.46964 20.7805C5.32899 20.6398 5.24997 20.4491 5.24997 20.2502V5.56066L3.53097 7.28116C3.46124 7.3509 3.37846 7.40621 3.28735 7.44395C3.19624 7.48169 3.09859 7.50111 2.99997 7.50111C2.90135 7.50111 2.8037 7.48169 2.7126 7.44395C2.62149 7.40621 2.5387 7.3509 2.46897 7.28116C2.39924 7.21143 2.34392 7.12865 2.30619 7.03754C2.26845 6.94643 2.24902 6.84878 2.24902 6.75016C2.24902 6.65155 2.26845 6.5539 2.30619 6.46279C2.34392 6.37168 2.39924 6.2889 2.46897 6.21916L5.46897 3.22066L5.47947 3.21016C5.62047 3.07322 5.80977 2.99737 6.00632 2.99905C6.20287 3.00074 6.39084 3.07982 6.52947 3.21916L9.52947 6.21916C9.5992 6.2888 9.65454 6.37148 9.69231 6.4625C9.73009 6.55352 9.74957 6.65109 9.74964 6.74963C9.74971 6.84818 9.73037 6.94577 9.69272 7.03685C9.65507 7.12792 9.59985 7.21068 9.53022 7.28041C9.46059 7.35015 9.3779 7.40548 9.28688 7.44326C9.19586 7.48103 9.0983 7.50051 8.99975 7.50058C8.90121 7.50065 8.80361 7.48131 8.71254 7.44366C8.62147 7.40601 8.5387 7.3508 8.46897 7.28116L6.74997 5.56066V20.2502Z"
                fill="#23415B"
            />
        </svg>

        <slot name="text">
            <p v-if="isAsc">Urutkan A-Z</p>
            <p v-else>Urutkan Z-A</p></slot
        >
    </button>
</template>

<script>
export default {
    name: "SortButton",
    props: {
        isAsc: {
            type: Boolean
        },
        changeSortMethod: {},
        isDisable: {
            type: Boolean
        }
    },
    methods: {
        changeSort() {
            if (
                this.changeSortMethod &&
                typeof this.changeSortMethod == "function"
            ) {
                this.changeSortMethod();
            }
        }
    }
};
</script>

<style></style>
