<template>
    <div style="width: 100%;">
        <SelectedOption
            @delete-option="deleteSelected"
            @select-selected="selectSelected"
            :defaultChange.sync="spesialisasiOptions.isDefault"
            :dataOption="spesialisasi"
            :canSelect="canSelect"
            :numberIndex="numberIndex"
            :propertyName="'spesialisasi'"
            style="padding-right: 12px;"
        >
        </SelectedOption>
        <div>
            <multiselect
                class="holiday-multiselect"
                :options="subOptions"
                track-by="id"
                label="nama"
                :multiple="true"
                v-model="selectedSub"
                :allow-empty="true"
                :close-on-select="true"
                :searchable="true"
                placeholder="Pilih subspesialisasi"
                @search-change="searchChange"
                :loading="isSubSelectLoading"
            >
                <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                        style="background: #fafafa;"
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} Subspesialisasi Terpilih</span
                    ></template
                >
                <div slot="beforeList" class="beforeList">
                    <p style="text-align:center;margin-top:10px;">
                        Ketik Untuk Mencari Subspesialisasi
                    </p>
                </div>
                <div slot="noOptions" class="noOptions">
                    <p style="text-align:center;margin-top:10px;">
                        Spesialisasi tidak memiliki Subspesialisasi
                    </p>
                </div>
                <div slot="noResult" class="noResult">
                    <p style="text-align:center;margin-top:10px;">
                        Tidak Ada Hasil
                    </p>
                </div>
            </multiselect>
            <div style="margin-top: 15px;">
                <div
                    v-for="(sub, index) in selectedSub"
                    :key="index"
                    class="selected-multi"
                >
                    <SelectedOption
                        :dataOption="sub"
                        :propertyName="'subspesialisasi'"
                        :numberIndex="index"
                        :parentNumber="numberIndex + 1"
                        :canSelect="sub.canSelect"
                        :defaultChange.sync="sub.isDefault"
                        @select-selected="selectSub(index)"
                        @delete-option="deleteSub(index)"
                    >
                    </SelectedOption>
                </div>
                <div
                    v-show="errorInput.subSpesialisasiDefault"
                    class="invalid-error "
                >
                    Default Subspesialisasi harus diisi
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import EyeFillIcon from "../../_icons/EyeFillIcon.vue";
import EyeIconSlashed from "../../_icons/EyeIconSlashed.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";
import SelectedOption from "./SelectedOption.vue";

export default {
    name: "SpesialisasiSub",
    components: {
        Multiselect,
        EyeFillIcon,
        EyeIconSlashed,
        TrashIcon,
        SelectedOption
    },
    data() {
        var vx = this;
        return {
            errorInput: {
                subSpesialisasiDefault: false
            },
            spesialisasiOptions: {
                isDefault: false,
                isPublish: false
            },
            selectedSub: [],
            subOptions: [],
            isSubSelectLoading: false,
            searchSubspesialisasi: _.debounce(async query => {
                try {
                    if (query) {
                        // if (vx.componentData) {
                        if (typeof vx.searchSubMethod == "function") {
                            vx.isSubSelectLoading = true;
                            const resp = await vx.searchSubMethod(
                                this.spesialisasi.id,
                                query
                            );
                            vx.isSubSelectLoading = false;
                            vx.subOptions = resp;
                        }
                        // }
                    } else {
                        // if (vx.componentData) {
                        if (typeof vx.searchSubMethod == "function") {
                            vx.isSubSelectLoading = true;
                            const resp = await vx.searchSubMethod(
                                this.spesialisasi.id
                            );
                            vx.isSubSelectLoading = false;
                            vx.subOptions = resp;
                        }
                        // }
                    }
                } catch (error) {
                    vx.isSubSelectLoading = false;
                    console.log(error);
                }
            }, 1000)
        };
    },
    props: {
        spesialisasi: {
            type: Object,
            default: () => {
                return {
                    id: 2322,
                    nama: "sebuah spesialisasi",
                    isDefault: false,
                    isPublish: false
                };
            }
        },
        numberIndex: {
            type: [Number, String],
            default: 1
        },
        canSelect: {
            type: Boolean,
            default: true
        },
        searchSubMethod: {
            type: Function
        }
    },
    watch: {
        spesialisasi: {
            handler(newVal, oldVal) {
                this.spesialisasiOptions.isDefault = newVal.isDefault;
                this.spesialisasiOptions.isPublish = newVal.isPublish;
                this.searchChange();
            },
            deep: true
        },
        "errorInput.subSpesialisasiDefault": {
            handler(newVal, oldVal) {
                this.$emit("update:errorSub", newVal);
            },
            deep: true
        },
        "spesialisasiOptions.isDefault": {
            handler(newVal, oldVal) {
                if (!newVal) {
                    if (this.selectedSub.length) {
                        this.selectedSub.forEach(sub => {
                            sub.isDefault = false;
                            sub.canSelect = true;
                        });
                    }
                } else {
                    this.selectedSub.forEach(sub => {
                        // sub.isDefault = false;
                        sub.canSelect = false;
                    });
                }
                this.$emit("update:defaultChange", newVal);
            }
        },
        selectedSub: {
            handler: function(val, oldVal) {
                if (!oldVal.length && val.length == 1) {
                    if (this.spesialisasi.isDefault) {
                        val[0].isDefault = true;
                    }
                }
                let isDefaultExist = false;
                val.forEach(sub => {
                    if (isDefaultExist) {
                        sub.isDefault = false;
                    }
                    if (sub.isDefault) {
                        isDefaultExist = true;
                    }
                });
                val.forEach(sub => {
                    if (this.spesialisasi.isDefault) {
                        if (isDefaultExist && !sub.isDefault) {
                            sub.canSelect = true;
                        } else {
                            sub.canSelect = false;
                        }
                    }
                });
                if (val.length && this.spesialisasi.isDefault) {
                    this.errorInput.subSpesialisasiDefault = !isDefaultExist;
                } else {
                    this.errorInput.subSpesialisasiDefault = false;
                }
                this.$emit("update:subspesialisasi", val);
            },
            deep: true
        }
    },
    methods: {
        deleteSelected(data) {
            this.$emit("delete-spesialisasi", data);
        },
        selectSelected(data) {
            this.$emit("select-default", data);
        },
        selectSub(index) {
            this.selectedSub[index].isPublish = !this.selectedSub[index]
                .isPublish;
        },
        deleteSub(index) {
            this.selectedSub.splice(index, 1);
        },
        searchChange(query) {
            this.searchSubspesialisasi(query);
        }
    },
    created() {
        this.spesialisasiOptions.isDefault = this.spesialisasi.isDefault;
        this.spesialisasiOptions.isPublish = this.spesialisasi.isPublish;
        if (this.spesialisasi.subSpesialisasi.length) {
            this.selectedSub = this.spesialisasi.subSpesialisasi;
        }
    },
    mounted() {
        this.searchChange();
    }
};
</script>

<style></style>
