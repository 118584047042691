<template>
    <b-dropdown-item class="elipsis-item" @click="seeDetail">
        <div style="width: 22px;">
            <i class="fa fa-user-lock font-weight-bold position-lock-user"></i>
        </div>
        <p>
            {{textStr}}
        </p>
    </b-dropdown-item>
</template>

<script>
import EyeIcon from "../../_icons/EyeIcon.vue";
export default {
    components: {
        EyeIcon
    },
    props: {
        textStr: {
            type: String,
            default: "Link Button "
        }
    },
    methods: {
        seeDetail() {
            this.$emit("link-button");
        }
    }
};
</script>

<style></style>
