<template>
  <div class="VuePagination row col-md-12 justify-content-center">
    <nav class="text-center">
      <ul class="pagination VuePagination__pagination" style="">
        <div class="VuePagination row col-md-12 justify-content-center">
          <nav class="text-center">
            <ul class="pagination VuePagination__pagination" style="">
              <li
                class="
                  VuePagination__pagination-item
                  page-item
                  VuePagination__pagination-item-prev-page
                "
                :class="page == 1 ? 'disabled': ''"
              >
                <button
                  :disabled="page == 1"
                   @click="changePage(page - 1)"
                  class="page-link"
                  >&lt; </button
                >
              </li>
              <template v-for="(item, index) in totalPage" > 
                <div :key="index">
                  <li class="VuePagination__pagination-item page-item" 
                    :class="page == index + 1 ? 'active' : ''"
                    v-if="checkPagination(index, page)"
                >
                    <button
                      class="page-link"
                      @click="changePage(index + 1)"
                      :class="page == index + 1 ? 'active' : ''"
                      >{{index + 1}}</button
                    >
                  </li>
                  <li class="VuePagination__pagination-item page-item disabled" 
                    v-if="index + 1 == 2  && page > 7|| index == totalPage - 2 && page + 3 < totalPage "
                >
                    <button
                      class="page-link disabled"
                      >...</button
                    >
                  </li>
                </div>
              </template>
              <li
                class="
                  VuePagination__pagination-item
                  page-item
                  VuePagination__pagination-item-next-page
                "
                :class="page == totalPage ? 'disabled': ''"
              >
                <button class="page-link" 
                :disabled="page == totalPage"
                @click="changePage(page + 1)"
                >&gt;</button>
              </li>
            </ul>
            <p class="VuePagination__count text-center col-md-12" style="">
              Showing {{totalList == 0 ? 0 : page * page_size - (page_size - 1)}} to {{page * page_size > totalList ? totalList : page * page_size}} of {{totalList}} records
            </p>
          </nav>
        </div>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    totalList: {
      type: Number,
      default: 0
    },
    page_size: {
      type: [Number, String],
      default: 10
    }
  },
  methods: {
    changePage(page) {
      this.$emit('change', page)
    },
    checkPagination(index, page) {
      if(index + 1 == 1 || index + 1 == this.totalPage) return true
      if(index > page - 4 && index <= page + 1 || page <= 7 && index < 7) return true
      if(index >= page + 4 && index <= page  ) return true

      return false
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalList / this.page_size)
    }
  },
};
</script>
