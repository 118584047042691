<template>
  <div class="modal fade" :id="modalCopy" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Akses Dokter 
            <template v-if="userId">
                 (sudah tersedia)
            </template>
            <template v-else>
                 (belum tersedia)
            </template>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div  v-if="userId">
            Akses sudah tersedia.
            <br>Silakan login menggunakan link back office.
        </div>
        <div  v-else>
            Akses belum tersedia.
            <br>Mohon untuk membuat akun terlebih dahulu kemudian Login melalui link berikut untuk menghubungkan akses dokter.
        </div>
        <label class="d-block" style="margin-top: 5px;margin-bottom: 0;">
            Link penghubung akses dokter :
        </label>
        <a class="text-break d-block" :href="linkUser" target="_blank">
            {{linkUser}}
        </a>
         <input type="hidden" id="linkUrl" :value="linkUser">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="copyCommand('linkUrl')">Copy Link</button>
        <!-- <button type="button" class="btn btn-primary" @click="openTelmed(linkData)">Join Telekonsul</button> -->
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
const blockUI = new BlockUI();
import BlockUI from "../../../_js/BlockUI";
export default {
    props: {
        modalCopy: { type: String, default: "modalCopy" },
        linkData: {type: Object, default: () => {}},
    },
    computed: {
        linkUser () {
            return this.linkData.link
        },
        userId () {
            return this.linkData.userId
        }
    },
    methods:{
      openTelmed(link){
        window.open(link)
      },
       close() {
            blockUI.unblockPage();
            $("#" + this.modalCopy).modal("hide");
            this.$emit('setCloseModal', false)
            // this.setShowModalLinkTelekonsul(false);
        },
        copyCommand(element_id){
            let testingCodeToCopy = document.querySelector('#linkUrl')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                 toastr.success('Berhasil disalin')
            } catch (err) {
                 toastr.error('Gagal disalin')
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    },
    mounted () {
      $("#" + this.modalCopy).modal();
    },
}
</script>

<style>

</style>