<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5 5.5L10 8L13.5 10.5V5.5Z"
            :fill="strokeColor"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9 4.5H3.5C2.94772 4.5 2.5 4.94772 2.5 5.5V10.5C2.5 11.0523 2.94772 11.5 3.5 11.5H9C9.55228 11.5 10 11.0523 10 10.5V5.5C10 4.94772 9.55228 4.5 9 4.5Z"
            :fill="strokeColor"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>
