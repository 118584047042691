<template>
    <div class="action-button">
        <button
            v-if="permissionObject.isCanEditDoctorSchedule"
            @click.prevent="editJadwal"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="Edit"
        >
            <EditIcon />
        </button>
        <button
            v-if="permissionObject.isCanCreateDoctorAbsence"
            @click.prevent="openAbsenceModal"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="Ketidakhadiran"
        >
            <CallendarIcon />
        </button>
        <button
            v-if="permissionObject.isCanDeleteDoctorSchedule"
            @click.prevent="delJadwal"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="Hapus"
        >
            <TrashIcon />
        </button>
    </div>
</template>

<script>
import EditIcon from "../../_icons/EditIcon.vue";
import EyeIcon from "../../_icons/EyeIcon.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";
import CallendarIcon from "../../_icons/CallendarIcon.vue";
export default {
    components: {
        EditIcon,
        EyeIcon,
        TrashIcon,
        CallendarIcon
    },
    props: {
        rowData: {
            type: Object
        },
        buttonMethod: {
            type: Object
        },
        permissionObject: {
            type: Object
        }
    },
    methods: {
        openAbsenceModal() {
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod.openAbsenceModalMethod ==
                    "function"
                ) {
                    this.buttonMethod.openAbsenceModalMethod(this.rowData);
                }
            }
        },
        editJadwal() {
            // openEditJadwalModalMethod;
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod.openEditJadwalModalMethod ==
                    "function"
                ) {
                    this.buttonMethod.openEditJadwalModalMethod(this.rowData);
                }
            }
        },
        delJadwal() {
            // openEditJadwalModalMethod;
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod.deleteJadwalConfirmationMethod ==
                    "function"
                ) {
                    this.buttonMethod.deleteJadwalConfirmationMethod(
                        {
                            id: this.rowData.jadwalId,
                            nama: `${this.rowData.hari} / ${
                                this.rowData.jadwalObject.jamMulai.slice(0,-3)
                            } - ${this.rowData.jadwalObject.jamSelesai.slice(0,-3)}`
                        },
                        "Data jadwal akan terhapus secara permanen",
                        "Apakah Anda yakin ingin menghapus data jadwal berikut?"
                    );
                }
            }
        }
    }
};
</script>

<style></style>
