<template>
    <div>
        <base-header
            base-title="Master Dokter"
            class="sticky-base-header"
        ></base-header>
        <DoctorDetailScreen
            :cardData="cardData"
            :isLoaded="isLoaded"
            :methodObject="methodObject"
            :btnActionMethod="btnActionMethod"
            :permissionObject="permissionObject"
        ></DoctorDetailScreen>
    </div>
</template>

<script>
import BaseHeader from "../_base/BaseHeader.vue";
import DoctorDetailScreen from "./DoctorDetailScreen.vue";
import ModalService from "../../services/modal.service";
import BlockUI from "../_js/BlockUI";

import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import DoctorDataClass from "./class/doctor-data-class";
import PermissionClass from "./class/permission-class";

import HospitalIcon from "../_icons/HospitalIcon.vue";
import HospitalIconSlashed from "../_icons/HospilatIconSlashed.vue";

import Telekonsul from "../_icons/Telekonsul.vue";
import TelekonsulSlashed from "../_icons/TelekonsulSlashed.vue";

import Mobile from "../_icons/Mobile.vue";
import MobileSlashed from "../_icons/MobileSlashed.vue";
import CallFill from "../_icons/CallFill.vue";
import CallSlashed from "../_icons/CallSlashed.vue";

import TambahJadwalModal from "./components/DoctorModal/TambahJadwal.vue";
import AbsenceModal from "./components/DoctorModal/AbsenceModal.vue";
import DokterModal from "./components/DoctorModal/AddDoctorModal.vue";
import AddKetidakhadiranModal from "../ketidakhadiran/component/ketidakhadiranModal/AddKetidakhadiranModal.vue";

import SwalCustom from "../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "DoctorDetailController",
    components: {
        DoctorDetailScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            methodObject: {
                openTambahJadwalModalMethod: vx.openTambahJadwalModal,
                openDokterModalMethod: vx.openDokterModal,
                deleteConfirmationMethod: vx.deleteDokterConfirmation,
                openKetidakhadiranModalMethod: vx.openKetidakhadiranModal,
                simpanProfileMhgMethod: vx.simpanProfileMhg
            },
            btnActionMethod: {
                openAbsenceModalMethod: vx.openAbsenceModal,
                openEditJadwalModalMethod: vx.openEditJadwalModal,
                deleteJadwalConfirmationMethod: vx.deleteJadwalConfirmation
            },
            masterDoctorRepo: RepositoryFactory.get("doctorCards"),
            PhotoRepository: RepositoryFactory.get("photo"),
            blockUi: new BlockUI(),
            icons: [
                {
                    icon: HospitalIcon,
                    condition: true,
                    type: "facetoface"
                },
                {
                    icon: HospitalIconSlashed,
                    condition: false,
                    type: "facetoface"
                },
                {
                    icon: Telekonsul,
                    condition: true,
                    type: "telekonsul"
                },
                {
                    icon: TelekonsulSlashed,
                    condition: false,
                    type: "telekonsul"
                },
                {
                    icon: Mobile,
                    condition: true,
                    type: "caredoctor"
                },
                {
                    icon: MobileSlashed,
                    condition: false,
                    type: "caredoctor"
                },
                {
                    icon: CallFill,
                    condition: true,
                    type: "callcenter"
                },
                {
                    icon: CallSlashed,
                    condition: false,
                    type: "callcenter"
                }
            ],
            cardData: null,
            detailDoctorData: null,
            isLoaded: false,
            permissionObject: {}
        };
    },
    methods: {
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    text: messageStr,
                    allowOutsideClick: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        async getDetailDoctor(isJadwal) {
            try {
                const resp = await this.masterDoctorRepo.getOneDoctorData(
                    this.$route.params.id
                );
                if (resp.error) {
                    throw resp;
                }
                if (!isJadwal) {
                    this.cardData = DoctorDataClass.detailDoctor(
                        resp,
                        this.icons
                    );
                    this.isLoaded = true;
                } else {
                    return resp.jadwals;
                }
            } catch (error) {
                await this.showErrorPopUp(error);
                this.$router.push({
                    name: "dokter-new"
                });
                console.log(error);
            }
        },
        async getKlinikList(searchQuery) {
            try {
                let params = {
                    page_size: 1
                };
                if (searchQuery) {
                    params.namaIndo = searchQuery;
                }
                const response = await this.masterDoctorRepo.getKlinikDataDropDown(
                    params
                );

                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getKlinikDataDropDown(
                    params
                );
                return DoctorDataClass.klinikDropDown(resp.data);
            } catch (error) {
                console.log(error, "getKlinikList");
            }
        },
        async getSpesialisasiList(searchQuery) {
            try {
                let params = {
                    page_size: 1
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                }
                const response = await this.masterDoctorRepo.getSpesialisasiDataDropDown(
                    params
                );
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getSpesialisasiDataDropDown(
                    params
                );
                return DoctorDataClass.spesialisasiDropDown(resp.data);
            } catch (error) {
                console.log(error, "getSpesialisasiList");
            }
        },
        async getSubSpesialisasiList(speId, searchQuery) {
            try {
                let params = {
                    page_size: 1,
                    spesialisasi: speId
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                }
                const response = await this.masterDoctorRepo.getSubSpesialisasiDataDropDown(
                    params
                );
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getSubSpesialisasiDataDropDown(
                    params
                );
                return DoctorDataClass.subSpesialisasiDropDown(resp.data);
            } catch (error) {
                console.log(error, "getSubSpesialisasiList");
            }
        },
        async postJadwalDokter(payload) {
            try {
                const resp = await this.masterDoctorRepo.postJadwalDokter(
                    payload
                );
                if (resp.error) {
                    throw resp;
                }
                return true;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        validateDoctorData(doctorData) {
            let data = JSON.parse(JSON.stringify(doctorData));
            let tanggalLahir;
            if (data.tglLahir) {
                tanggalLahir = data.tglLahir;
            }
            let payload = {
                ID: data.id,
                nama: data.nama,
                // tanggalLahir: data.tglLahir + "T00:00:00Z",
                jenisKelamin: data.gender,
                dokterKliniks: data.klinik,
                dokterSpesialisasis: data.spesialisasi,
                dokterSubSpesialisasis: data.subSpesialisasi,
                tarif: data.tarifTelekonsultasi,
                isPublish: data.isPublishProfile,
                isBookAllow: data.tipeBooking
            };
            if (tanggalLahir) {
                payload.tanggalLahir = tanggalLahir + "T00:00:00Z";
            }
            return payload;
        },
        openTambahJadwalModal() {
            let closeModalfunc;
            let payload = {
                initialData: {
                    dokter: {
                        id: this.cardData.doctorCardData.doctorId,
                        nama: this.cardData.doctorCardData.doctorName
                    }
                },
                action: async data => {
                    try {
                        const validated = DoctorDataClass.addJadwalDokter(data);
                        if (validated) {
                            const confirmResult = await this.dataConfirmation(
                                {
                                    ID: data.dokter.id,
                                    nama: data.dokter.nama
                                },
                                "Data jadwal dokter akan ditambah",
                                "Apakah Anda yakin ingin menambah data jadwal dokter berikut?",
                                "Ya, Tambah"
                            );
                            if (confirmResult.value) {
                                const unblock = this.blockUi.blockModal();
                                const resp = await this.postJadwalDokter(
                                    validated
                                );
                                unblock();
                                if (resp) {
                                    if (typeof closeModalfunc == "function") {
                                        closeModalfunc();
                                    }
                                    this.showPopUp(
                                        "Data jadwal berhasil di tambah",
                                        "success",
                                        "successImg"
                                    );
                                    await this.getDetailDoctor();
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        this.showErrorPopUp(error);
                    }
                }
            };
            let { close } = ModalService.open(TambahJadwalModal, payload);
            closeModalfunc = close;
        },
        async getOneJadwalDokter(id) {
            try {
                const resp = await this.masterDoctorRepo.getOneJadwalDokter(id);
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async getTarifTelekonsultasiList(searchQuery) {
            try {
                let params = {
                    page_size: 1
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                }
                const response = await this.masterDoctorRepo.getTarifTelekonsultasiDataDropDown(
                    params
                );
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getTarifTelekonsultasiDataDropDown(
                    params
                );
                return DoctorDataClass.tarifKonsultasiDropDown(resp.data);
            } catch (error) {
                console.log(error, "getTarifTelekonsultasiList");
                this.showErrorPopUp(error);
            }
        },
        async putJadwalDokter(payload) {
            try {
                const resp = await this.masterDoctorRepo.putJadwalDokter(
                    payload
                );
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async postDataAbsence(payload) {
            try {
                const resp = await this.masterDoctorRepo.postAbsenceData(
                    payload
                );
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
                return error;
            }
        },
        async openEditJadwalModal({ jadwalId }) {
            let closeModalfunc;
            try {
                const unblock = this.blockUi.blockPage();
                const jadwalDokter = await this.getOneJadwalDokter(jadwalId);
                unblock();
                if (!jadwalDokter) {
                    throw jadwalDokter;
                }
                const initialData = DoctorDataClass.initialDataEditJadwal(
                    jadwalDokter
                );
                const dokterObject = jadwalDokter.dokter;
                let payload = {
                    initialData: initialData,
                    isEdit: true,
                    action: async data => {
                        try {
                            const validated = DoctorDataClass.editJadwalDokter(
                                data,
                                dokterObject
                            );
                            if (validated) {
                                const confirmResult = await this.dataConfirmation(
                                    {
                                        ID: data.dokter.id,
                                        nama: data.dokter.nama
                                    },
                                    "Data jadwal dokter akan diperbarui",
                                    "Apakah Anda yakin ingin memperbarui data jadwal dokter berikut?",
                                    "Ya, Perbarui"
                                );
                                if (confirmResult.value) {
                                    const unblock = this.blockUi.blockModal();
                                    const resp = await this.putJadwalDokter(
                                        validated
                                    );
                                    unblock();
                                    if (resp) {
                                        if (
                                            typeof closeModalfunc == "function"
                                        ) {
                                            closeModalfunc();
                                        }
                                        this.showPopUp(
                                            "Data jadwal berhasil di perbarui",
                                            "success",
                                            "successImg"
                                        );
                                        await this.getDetailDoctor();
                                    }
                                }
                            }
                        } catch (error) {
                            console.log(error);
                            this.showErrorPopUp(error);
                        }
                    }
                };
                let { close } = ModalService.open(TambahJadwalModal, payload);
                closeModalfunc = close;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async dataConfirmation(
            { ID, nama, alasan },
            text,
            title,
            buttonConfirm
        ) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${nama}</p>
                        <p>  ${ID ? `ID:${ID}` : ``} </p>
                        <p>  ${alasan ? `Alasan:${alasan}` : ``} </p>
                     </div>`;
                html += `<p class="sub-delete__text">${text}</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: title,
                        html: html,
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: buttonConfirm ? buttonConfirm : "Ya",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async putEditDoctorData(payload) {
            try {
                const resp = await this.masterDoctorRepo.putEditDoctorData(
                    payload
                );
                return resp;
            } catch (error) {
                console.log(error, "putEditDoctorData");
                this.showErrorPopUp(error);
            }
        },
        async postPhoto(payload) {
            try {
                const response = await this.PhotoRepository.createPhoto(
                    payload
                );
                return response;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async updatePhoto(payload) {
            try {
                const response = await this.PhotoRepository.updatePhoto(
                    payload
                );
                return response;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async deletePhoto(photoId) {
            try {
                const response = await this.PhotoRepository.deletePhoto(
                    photoId
                );
                return response;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async getPhotoById(photoId) {
            try {
                const response = await this.PhotoRepository.getPhoto(photoId);
                return response.data;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async openDokterModal(isViewOnly) {
            let unblock;
            let closeModalfunc;

            try {
                unblock = this.blockUi.blockPage();

                const initialData = await this.masterDoctorRepo.getOneDoctorData(
                    this.$route.params.id
                );
                let photoMethodStr = `postPhoto`;
                const fotoId = initialData.fotoId;
                let photo;
                if (fotoId) {
                    photoMethodStr = `updatePhoto`;

                    const resp = await this.getPhotoById(fotoId);
                    photo = resp;
                }
                unblock();
                let payload = {
                    initialData: initialData,
                    photo: photo,
                    isViewOnly: isViewOnly,
                    modalTitle: isViewOnly ? "Detail Dokter" : "Edit Dokter",
                    permissionObject: this.permissionObject,
                    action: async data => {
                        let {
                            validated,
                            image
                        } = DoctorDataClass.putDataDokter(data);
                        try {
                            if (validated) {
                                const confirmResult = await this.dataConfirmation(
                                    validated,
                                    "Data dokter akan diperbarui",
                                    "Apakah Anda yakin ingin memperbarui data dokter berikut?",
                                    "Ya, Perbarui"
                                );
                                // console.log(confirmResult, "confirmResult");
                                if (confirmResult.value) {
                                    const unblock = this.blockUi.blockModal();
                                    let photoId;
                                    // console.log(image);
                                    if (image) {
                                        let responsePhoto = await this[
                                            photoMethodStr
                                        ](image);
                                        photoId = responsePhoto.data.id;
                                    } else if (fotoId) {
                                        await this.deletePhoto(fotoId);
                                    }
                                    let payload = { ...validated };
                                    if (photoId) {
                                        payload.fotoId = photoId;
                                    }
                                    const resp = await this.putEditDoctorData(
                                        payload
                                    );
                                    unblock();
                                    // console.log("resp", resp);
                                    if (resp.error) {
                                        throw resp;
                                    }
                                    if (typeof closeModalfunc == "function") {
                                        closeModalfunc();
                                    }
                                    this.showPopUp(
                                        "Data dokter berhasil di perbarui",
                                        "success",
                                        "successImg"
                                    );
                                    this.getDetailDoctor();
                                }
                            }
                        } catch (error) {
                            if (typeof unblock == "function") {
                                unblock();
                            }
                            this.showErrorPopUp(error);
                            console.log(error);
                        }
                    },
                    searchKlinik: async name => {
                        return await this.getKlinikList(name);
                    },
                    searchSpesialisasi: async name => {
                        return await this.getSpesialisasiList(name);
                    },
                    searchSubSpesialisasi: async (speId, name) => {
                        return await this.getSubSpesialisasiList(speId, name);
                    },
                    searchTarifKonsultasi: async name => {
                        return await this.getTarifTelekonsultasiList(name);
                    }
                };
                let { close } = ModalService.open(DokterModal, payload);
                closeModalfunc = close;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        openAbsenceModal(rowData) {
            let closeModalfunc;
            const viewData = {
                nama: this.cardData.doctorCardData.doctorName,
                day: rowData.hari,
                jamMulai: rowData.jadwalObject.jamMulai.slice(0, -3),
                jamSelesai: rowData.jadwalObject.jamSelesai.slice(0, -3)
            };
            let payload = {
                initialData: {
                    // jadwalId: rowData.jadwalId,
                    // dokterID: rowData.dokterID,
                    viewData: viewData,
                    numDay: rowData.jadwalObject.hari
                },
                action: async data => {
                    let unblock;
                    try {
                        const payload = DoctorDataClass.postSingleAbsence(
                            data,
                            rowData.jadwalObject
                        );

                        // console.log(payload, "payload");
                        if (payload) {
                            const confirmResult = await this.dataConfirmation(
                                {
                                    nama: `${rowData.hari} - ${moment(
                                        payload.tanggal,
                                        "YYYY-MM-DD"
                                    ).format(`DD/MMM/YYYY`)}`,
                                    alasan: payload.alasan
                                },
                                "Data ketidakhadiran dokter akan ditambah",
                                "Apakah Anda yakin ingin menambah data ketidakhadiran dokter berikut?",
                                "Ya, Tambah"
                            );
                            if (confirmResult.value) {
                                unblock = this.blockUi.blockModal();
                                const response = await this.postDataAbsence([
                                    payload
                                ]);
                                unblock();
                                if (response.err) {
                                    throw response;
                                }
                                if (typeof closeModalfunc == "function") {
                                    closeModalfunc();
                                }
                                this.showPopUp(
                                    "Data ketidakhadiran dokter berhasil di tambah",
                                    "success",
                                    "successImg"
                                );
                                this.getDetailDoctor();
                            }
                        }
                    } catch (error) {
                        if (typeof unblock == "function") {
                            unblock();
                        }
                        this.showErrorPopUp(error);
                        console.log(error);
                    }
                }
            };
            let { close } = ModalService.open(AbsenceModal, payload);
            closeModalfunc = close;
        },
        async deleteJadwalDokter(id) {
            try {
                const resp = await this.masterDoctorRepo.deleteJadwalDokter(id);
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                console.log(error, "deleteJadwalDokter");
                this.showErrorPopUp(error);
            }
        },
        async simpanProfileMhg(payload) {
            let unblock;
            try {
                //              "dokterId": 29,
                // "profileCss": "1",
                // "profileMhg": "1",
                // "profileOvveride" : "1"
                const confirmResult = await this.dataConfirmation(
                    {
                        nama: `Data Profile CareDokter`,
                        alasan: ``
                    },
                    "Data Profile dokter akan ditambah dan Tampil pada Applikasi CareDokter",
                    "Apakah Anda yakin ingin menambah data Profile dokter berikut?",
                    "Ya, Tambah"
                );
                if (confirmResult.value) {
                    unblock = this.blockUi.blockPage();
                    const resp = await this.masterDoctorRepo.postProfileAndCss({
                        profileCss: payload.css,
                        profileMhg: payload.mandaya_group,
                        profileOvveride: payload.care_dokter,
                        dokterId: this.cardData.doctorCardData.doctorId
                    });
                    unblock();
                    if (resp.error) {
                        throw resp;
                    }
                    this.showPopUp(
                        "Profile data dokter berhasil di perbarui",
                        "success",
                        "successImg"
                    );
                    return resp;
                }
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error, "deleteJadwalDokter");
                this.showErrorPopUp(error);
            }
        },
        async deleteJadwalConfirmation({ nama, id }, text, title) {
            let unblock;
            try {
                const result = await this.deleteConfirmation(
                    { nama, id },
                    text,
                    title
                );
                if (result.value) {
                    unblock = this.blockUi.blockPage();
                    const resp = await this.deleteJadwalDokter(id);
                    unblock();
                    if (resp.error) {
                        throw resp;
                    }
                    this.getDetailDoctor();
                    this.showPopUp(
                        "Data jadwal berhasil di hapus",
                        "success",
                        "successImg"
                    );
                }
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                this.showErrorPopUp(error);
            }
        },
        async deleteConfirmation({ nama, id }, text, title) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                        <p>${nama}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `<p class="sub-delete__text">${text}</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: title,
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async deleteDokterConfirmation({ id, nama }, text, title) {
            try {
                const result = await this.deleteConfirmation(
                    { id, nama },
                    text,
                    title
                );
                if (result.value) {
                    this.deleteDokter(id);
                }
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteDokter(id) {
            let unblock;
            try {
                unblock = this.blockUi.blockPage();
                const resp = await this.masterDoctorRepo.delDataDoctor(id);
                unblock();
                if (resp.error) {
                    throw resp;
                }
                this.showPopUp(
                    "Data dokter berhasil dihapus",
                    "success",
                    "successImg"
                );
                this.$router.push({
                    name: "dokter-new"
                });
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async ketidakHadiranByDokterId() {
            try {
                const jadwals = await this.getDetailDoctor(true);
                return DoctorDataClass.jadwalsMapper(jadwals);
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        openKetidakhadiranModal() {
            let closeModalFunc;
            let unblock;
            let payload = {
                getJadwalByDokterId: this.ketidakHadiranByDokterId,
                action: async data => {
                    try {
                        const { jadwal, alasan, nama, id } = data;
                        const payload = DoctorDataClass.postKetidakhadiran({
                            jadwal,
                            alasan
                        });
                        // console.log(payload, "payload");
                        if (payload) {
                            const result = await this.dataConfirmation(
                                {
                                    nama,
                                    ID: id
                                },
                                "Ketidakhadiran dokter akan ditambah",
                                "Apakah Anda yakin ingin menambah ketidakhadiran dokter berikut?",
                                "Ya, Tambah"
                            );
                            if (result.value) {
                                unblock = this.blockUi.blockModal();
                                const resp = await this.masterDoctorRepo.postKetidakhadiran(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    console.log(resp.error, "resp.error");
                                    throw resp;
                                } else {
                                    closeModalFunc();
                                    this.getDetailDoctor();
                                    this.showPopUp(
                                        "Data Ketidakhadiran berhasil ditambah",
                                        "success",
                                        "successImg"
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        this.showErrorPopUp(error);
                    }
                },
                isDoctorDetail: true,
                selectedDoctor: {
                    id: this.cardData.doctorCardData.doctorId,
                    nama: this.cardData.doctorCardData.doctorName
                }
            };
            let { close } = ModalService.open(AddKetidakhadiranModal, payload);
            closeModalFunc = close;
        },
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        }
    },
    created() {
        this.initPermissionCheck();
        this.getDetailDoctor();
    }
};
</script>

<style></style>
