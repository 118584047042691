<template>
    <table class="table table-bordered jadwal-table">
        <thead class="jadwal-head">
            <tr>
                <th v-for="(head, index) in tableHeader" :key="index">
                    {{ head }}
                </th>
            </tr>
        </thead>
        <tbody class="table-data">
            <tr v-for="(row, index) in tableData" :key="index">
                <td class="col-2" v-for="(cell, index) in row" :key="index">
                    <PublishChips
                        v-if="cell.text"
                        :isPublished="cell.isActive"
                        :textString="cell.text"
                    />
                    <div
                        style="display: flex;justify-content: center;align-items: center;"
                        v-else-if="cell.hexColor"
                    >
                        <div
                            :style="
                                `background:${
                                    cell.hexColor
                                };width:12px;height:12px;margin-right: 5px;border-radius: 3px;`
                            "
                        ></div>
                        <p style="margin-bottom:0;">{{ cell.hari }}</p>
                    </div>
                    <slot
                        v-else-if="cell.dokterID"
                        name="table-action"
                        :row="cell"
                    >
                    </slot>
                    <p v-else>
                        {{ cell }}
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PublishChips from "../components/PublishChips.vue";
export default {
    components: {
        PublishChips
    },
    props: {
        tableHeader: {
            type: Array,
            default: () => []
        },
        tableData: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style></style>
