<template>
    <div class="text-multi">
        <p class="text-multi__number" style="width:20px;">
            {{
                parentNumber
                    ? `${parentNumber}.${numberIndex + 1}`
                    : `${numberIndex + 1}.`
            }}
        </p>
        &nbsp;
        <p class="text-multi__nama" :title="nama">
            {{ nama }}
        </p>
    </div>
</template>

<script>
export default {
    name: "SelectedText",
    props: {
        parentNumber: {
            type: [String, Number]
        },
        numberIndex: {
            type: [String, Number]
        },
        nama: {
            type: String
        }
    }
};
</script>

<style></style>
