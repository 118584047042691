<template>
    <div>
        <div class="doctor-avatar" style="margin-bottom: 12px;">
            <img
                style="border-radius: 50%;width: 125px;object-fit: contain;height: 125px;"
                :src="baseUrl + '/gallery/api/image/alt/' + avatar"
                alt=""
                @error="replaceByDefault"
            />
        </div>
        <div class="doctor-gender-tgl">
            <p class="tgl-lahir"></p>
            <p>
                {{ tglLahir }}
            </p>
        </div>
        <div class="doctor-gender-tgl">
            <p>
                Gender
            </p>
            <p>
                {{ gender }}
            </p>
        </div>
    </div>
</template>

<script>
import dummyImage from "../assets/placeholder-doctor.png";
export default {
    data() {
        return {
            image: dummyImage,
            baseUrl: process.env.VUE_APP_API_URL
        };
    },
    methods: {
        replaceByDefault(e) {
            e.target.src = dummyImage;
        }
    },
    props: {
        tglLahir: {
            type: String,
            default: " - "
        },
        gender: {
            type: String,
            default: " - "
        },
        avatar: {
            type: String
        }
    }
};
</script>

<style></style>
