<template>
    <button
        @click.prevent="deleteEmit"
        class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
        title="Hapus"
    >
        <TrashIcon :strokeColor="'#000000'" />
    </button>
</template>

<script>
import TrashIcon from "../_icons/TrashIcon.vue";
export default {
    name: "TrashButton",
    components: {
        TrashIcon
    },
    methods: {
        deleteEmit() {
            this.$emit("delete-emit");
        }
    }
};
</script>

<style></style>
