<template>
    <div>
        <base-header base-title="Dokter"></base-header>
        <!-- begin:: Content -->
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title v-bind:head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar" id="head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <div>
                                <b-dropdown
                                    id="dropdown-left"
                                    :text="`Publish: ${publishData}`"
                                    variant="outline-primary"
                                    class="m-2"
                                >
                                    <b-dropdown-item
                                        @click="filterPublished(`All`)"
                                        >All</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        @click="filterPublished(`Published`)"
                                        >Published</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        @click="filterPublished(`UnPublished`)"
                                        >UnPublished</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                            <auto-refresh
                                v-bind:table-name="tableName"
                                :on-refresh="fetch"
                            ></auto-refresh>
                            &nbsp;&nbsp;
                            <template
                                v-if="
                                    checkPermission(
                                        'StatusJadwalDokterResource.GET.StatusJadwalDokter'
                                    )
                                "
                            >
                                <router-link
                                    class="btn btn-default btn-bold btn-upper btn-font-sm"
                                    :to="{ name: 'absen-dokter' }"
                                >
                                    <i class="la la-calendar-times-o"></i>
                                    Ketidakhadiran
                                </router-link>
                            </template>
                            <template
                                v-if="
                                    checkPermission(
                                        'DokterResource.POST.Dokter'
                                    )
                                "
                            >
                                &nbsp;&nbsp;
                                <base-button-modal
                                    button-text="Tambah Dokter"
                                    button-icon="flaticon2-add-1"
                                    button-name="modal_doctor"
                                    v-bind:onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div
                        v-if="showLoading"
                        class="m-auto p-5 d-flex flex-row h-100"
                    >
                        <div
                            class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                        ></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <table-grouping
                            v-bind:table="table"
                            v-bind:doctor-vue="this"
                            @change="searchTable"
                        ></table-grouping>
                        <pagination
                            :total-list="totalDoctor"
                            @change="pagination"
                            :page="page"
                        />
                    </div>
                </div>
                <form-modal
                    v-if="isShowModal"
                    :show-modal.sync="isShowModal"
                    @event="fetch"
                    :modal-name="modalName"
                    :modal-data="dataEdit"
                ></form-modal>
                <form-modal-schedule
                    v-if="isShowModalSchedule"
                    :show-modal.sync="isShowModalSchedule"
                    @event="fetch"
                    :modal-name="modalNameSchedule"
                    :modal-data="dataEditSchedule"
                >
                </form-modal-schedule>
                <form-modal-login
                    v-if="isShowModalLogin"
                    :show-modal.sync="isShowModalLogin"
                    @event="fetch"
                    :modal-name="modalNameLogin"
                    :modal-data="dataEditLogin"
                ></form-modal-login>
                <form-modal-absent
                    v-if="isShowModalAbsent"
                    :show-modal.sync="isShowModalAbsent"
                    @event="fetch"
                    :modal-name="modalNameAbsent"
                    :modal-data="dataEditAbsent"
                ></form-modal-absent>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "../_general/AutoRefresh";
import BaseTitle from "../_base/BaseTitle";
import BaseHeader from "../_base/BaseHeader";
import BaseButtonModal from "../_base/BaseButtonModal";
import TableGrouping from "./_components/TableGrouping";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
import Pagination from "./_components/Pagination.vue";

export default {
    components: {
        AutoRefresh,
        TableGrouping,
        BaseTitle,
        BaseHeader,
        BaseButtonModal,
        Pagination,
        FormModal: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-doctor" */ "./_components/FormModal.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormModalSchedule: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-schedule" */ "./_components/FormModalSchedule.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormModalLogin: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "doctor-login" */ "./_components/DoctorLogin.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormModalAbsent: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-absent" */ "./_components/FormModalAbsent.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            publishData: "All",
            isLoaded: false,
            showLoading: true,
            isShowModal: false,
            isShowModalSchedule: false,
            isShowModalAbsent: false,
            isShowModalLogin: false,
            dataEdit: null,
            totalDoctor: 0,
            dataEditSchedule: null,
            dataEditLogin: null,
            dataEditAbsent: null,
            headTitle: "Daftar Dokter",
            tableName: "table_doctor",
            modalName: "modal_doctor",
            modalNameSchedule: "modal_schedule",
            modalNameAbsent: "modal_absent",
            modalNameLogin: "modal_login",
            listDokter: [],
            allListDokter: [],
            filterGroup: [],
            page: 1,
            table: {
                tableName: "table_doctor",
                tableDataResult: [],
                tableColumns: [
                    {
                        title: "id",
                        data: "dokter.id"
                    },
                    {
                        title: "Nama",
                        data: "dokter.nama"
                    },
                    {
                        title: "Poli",
                        data: function(row, type) {
                            if (row.dokter.tujuanRujukan) {
                                if (row.dokter.tujuanRujukan.nama) {
                                    return row.dokter.tujuanRujukan.nama;
                                }
                            }
                            return "-";
                        }
                    },
                    {
                        title: "Hari",
                        data: "hari"
                    },
                    {
                        title: "Jam Mulai",
                        data: "jamMulai"
                    },
                    {
                        title: "Jam Selesai",
                        data: "jamSelesai"
                    },
                    {
                        title: "Duration",
                        data: "durationInMinute"
                    },
                    {
                        title: "Tanggal Mulai",
                        data: "tanggalMulai"
                    },

                    {
                        title: "Tanggal Selesai",
                        data: "tanggalSelesai"
                    },
                    {
                        title: "Actions",
                        data: "id",
                        createdCell: function(
                            cell,
                            cellData,
                            rowData,
                            rowIndex,
                            colIndex
                        ) {
                            let btnAction = Vue.extend(BtnAction);
                            let instance = new btnAction({
                                propsData: {
                                    jadwalVue: vx,
                                    rowData: rowData
                                }
                            });
                            instance.$mount();
                            $(cell)
                                .empty()
                                .append(instance.$el);
                        }
                    },
                    {
                        title: "User Id",
                        data: "dokter.userId"
                    },
                    {
                        title: "Published",
                        data: "dokter.isPublished"
                    }
                ],
                tableColumnDefs: [
                    {
                        targets: [1, 9],
                        orderable: false
                    },
                    {
                        // hide columns by index number
                        targets: [0, 2, 10, 11],
                        visible: false
                    },
                    {
                        targets: [3, 4, 5, 6],
                        orderable: false
                    },
                    {
                        targets: [7, 8],
                        orderable: false,
                        width: "15%",
                        render: function(t, e, a, n) {
                            if (t) {
                                var momentDate = moment(t);
                                return momentDate.format("DD/MM/YYYY");
                            } else {
                                return "";
                            }
                        }
                    },
                    {
                        targets: -1,
                        responsivePriority: 1
                    }
                ],
                tableFilter: [
                    {
                        title: "Nama",
                        type: "text"
                    },
                    {
                        title: "Hari",
                        type: "text"
                    },
                    {
                        title: "Jam Mulai",
                        type: "text"
                    },
                    {
                        title: "Jam Selesai",
                        type: "text"
                    },
                    {
                        title: "Duration",
                        type: "text"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    },
                    {
                        title: "Tanggal Mulai",
                        type: "datepicker_today"
                    },
                    {
                        title: "Tanggal Selesai",
                        type: "datepicker"
                    }
                ]
            },
            listPublishDataAll: []
        };
    },
    watch: {
        isShowModal: function(val) {
            if (!val) {
                this.dataEdit = null;
            }
        },
        isShowModalSchedule: function(val) {
            if (!val) {
                this.dataEditSchedule = null;
            }
        },
        isShowModalLogin: function(val) {
            if (!val) {
                this.dataEditLogin = null;
            }
        },
        isShowModalAbsent: function(val) {
            if (!val) {
                this.dataEditAbsent = null;
            }
        }
    },
    methods: {
        pagination(newVal) {
            this.page = newVal;
            var datatable = $("#" + this.tableName).DataTable();
            const listSpliceDokter = this.listDokter.slice(
                newVal * 10 - 10,
                newVal * 10
            );
            datatable.clear();
            datatable.rows.add(listSpliceDokter);
            datatable.draw("full-hold");
        },
        filterbyName(data, key, value, inObject = false) {
            let result = [];
            if (inObject) {
                data.forEach(item => {
                    const searchData = item.dokter[key].toString();
                    const idData = item.dokter.id.toString();
                    if (
                        searchData.toUpperCase().indexOf(value) > -1 ||
                        idData.indexOf(value) > -1
                    ) {
                        result.push(item);
                    }
                });
            } else {
                const arrStartDate = this.filterGroup.filter(
                    item => item.e == "7"
                )[0] || { a: "" };
                const arrEndDate = this.filterGroup.filter(
                    item => item.e == "8"
                )[0] || { a: "" };
                data.forEach(item => {
                    if (key == "tanggalMulai" || key == "tanggalSelesai") {
                        //  cek result
                        const searchDate = {
                            searchStartDate: arrStartDate.a,
                            searchEndDate: arrEndDate.a,
                            dataStartDate: item.tanggalMulai,
                            dataEndDate: item.tanggalSelesai
                        };
                        const showData = helpers.cekRangeDate(searchDate);
                        if (showData) result.push(item);
                    } else if (item[key]) {
                        if (
                            item[key]
                                .toString()
                                .toUpperCase()
                                .indexOf(value) > -1
                        ) {
                            result.push(item);
                        }
                    }
                });
            }
            return result;
        },
        changeFormatDate(date) {
            const splitDate = date.split("/");
            return splitDate.length > 0
                ? `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
                : "";
        },
        searchTable(data) {
            this.filterGroup = data;
            const listAll = JSON.stringify(this.allListDokter);
            this.listDokter = JSON.parse(listAll);
            if (data.length > 0) {
                data.forEach(item => {
                    if (item.e == 1) {
                        this.listDokter = this.filterbyName(
                            this.listDokter,
                            "nama",
                            item.a ? item.a.toUpperCase() : "",
                            true
                        );
                    } else {
                        let key;
                        switch (item.e) {
                            case "3":
                                key = "hari";
                                break;
                            case "4":
                                key = "jamMulai";
                                break;
                            case "5":
                                key = "jamSelesai";
                                break;
                            case "6":
                                key = "durationInMinute";
                                break;
                            case "7":
                                key = "tanggalMulai";
                                item.a = this.changeFormatDate(item.a);
                                break;
                            case "8":
                                key = "tanggalSelesai";
                                item.a = this.changeFormatDate(item.a);
                                break;
                        }

                        this.listDokter = this.filterbyName(
                            this.listDokter,
                            key,
                            item.a ? item.a.toString().toUpperCase() : ""
                        );
                    }
                });
            } else {
                const listAll = JSON.stringify(this.allListDokter);
                this.listDokter = JSON.parse(listAll);
            }
            var datatable = $("#" + this.tableName).DataTable();
            const listSpliceDokter = this.listDokter.slice(0, 10);
            datatable.clear();
            datatable.rows.add(listSpliceDokter);
            datatable.draw("full-hold");
            this.totalDoctor = this.listDokter.length;
            this.table.tableDataResult = listSpliceDokter;
            this.page = 1;
        },
        filterPublished(filter1) {
            // let dataDokter = this.allListDokter;
            // const listAll = JSON.stringify(this.allListDokter);
            const stringifyListDokter = JSON.stringify(this.listPublishDataAll);
            this.allListDokter = JSON.parse(stringifyListDokter);
            this.publishData = filter1;
            if (filter1 != "All") {
                if (filter1 == "Published") {
                    this.allListDokter = this.allListDokter.filter(item => {
                        if (item.dokter.isPublished) {
                            return item;
                        }
                    });
                } else if (filter1 == "UnPublished") {
                    this.allListDokter = this.allListDokter.filter(item => {
                        if (!item.dokter.isPublished) {
                            return item;
                        }
                    });
                }
            } else {
                this.allListDokter = JSON.parse(stringifyListDokter);
            }

            this.searchTable(this.filterGroup);
        },
        onLoaded: function(t) {
            t == 0 ? (this.isLoading = false) : (this.isLoading = true);
        },
        showModal: function() {
            this.isShowModal = true;
        },
        showModalSchedule: function() {
            this.isShowModalSchedule = true;
        },
        showModalLogin: function() {
            this.isShowModalLogin = true;
        },
        showModalAbsent: function() {
            this.isShowModalAbsent = true;
        },
        changeData(data) {
            var result = [];

            $.each(data, function(key, value) {
                if (value.jadwals.length > 0) {
                    $.each(value.jadwals, function(key2, value2) {
                        value2.jam = value2.jam.map(item => {
                            return {
                                ...item,
                                hari: `${helpers.daysToId(value2.hari)}`
                            };
                        });

                        // const value3 = value2.jam.filter(item => helpers.checkShowDisplay({start: item.tanggalMulai, end: item.tanggalSelesai, formatDate: 'YYYY-MM-DD'}))
                        result = [...result, ...value2.jam];
                    });
                } else {
                    const tempVal = {
                        dokter: {
                            isPublished: value.isPublished,
                            id: value.id,
                            nama: value.nama,
                            tanggalLahir: value.tanggalLahir,
                            tujuanRujukan: value.tujuanRujukan,
                            userId: null
                        },
                        hari: "-",
                        id: null,
                        jamMulai: null,
                        jamSelesai: null,
                        tanggalMulai: null,
                        tanggalSelesai: null,
                        durationInMinute: null
                    };
                    result.push(tempVal);
                }
            });

            return result;
        },
        async fetch(resolve) {
            this.listDokter = [];
            const defaultParams =  new URLSearchParams()
            defaultParams.append('sort', 'nama,asc')
            defaultParams.append('sort', 'id,asc')
            new Promise((resolve, reject) => {
                DoctorRepository.getWithConfig(defaultParams, resolve, reject);
            })
                .then(response => {
                    new Promise((resolve, reject) => {
                        // DoctorRepository.fetch({size: 10, page: 0}).then(response => {
                        const data = response.data;
                        var result = [];
                        result = this.changeData(data);
                        this.isLoaded = true;
                        this.showLoading = false;
                        const stringifyListDokter = JSON.stringify(result);
                        this.allListDokter = JSON.parse(stringifyListDokter);
                        this.listPublishDataAll = JSON.parse(
                            stringifyListDokter
                        );
                        this.searchTable(this.filterGroup);
                        resolve();
                    });
                })
                .catch(error => {
                    var msg = error.message ? error.message : error;
                    this.showToastError("Doctor : " + msg);
                });
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    mounted() {
        this.fetch();
    }
};
</script>
