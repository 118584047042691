<template>
    <Modal class="custom-modal">
        <ModalHeader class="custom-header">
            <h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form style="position:relative;">
                <div class="form-group row">
                    <label class="col-4 label-form-doctor form-label">
                        Tanggal <span style="color:red;">*</span>
                    </label>
                    <div v-if="daysDisabled" class="col-8">
                        <DatePicker
                            class="custom-datepicker"
                            :class="{
                                'input-invalid': errorsInput.tanggal
                            }"
                            :changedValue.sync="absenceData.tanggal"
                            :id-date-picker="'jadwal_date_' + 'dokter_jadwal'"
                            :picker-value="absenceData.tanggal"
                            place-holder="Pilih tanggal ketidakhadiran"
                            :is-validate="false"
                            start-date="0d"
                            end-date=""
                            :daysDisabled="daysDisabled"
                        >
                        </DatePicker>
                        <div v-show="errorsInput.tanggal" class="invalid-error">
                            Tanggal harus diisi
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4 label-form-doctor form-label">
                        Alasan <span style="color:red;">*</span>
                    </label>
                    <div class="col-8">
                        <textarea
                            v-model="absenceData.description"
                            placeholder="Tulis alasan ketidakhadiran"
                            name=""
                            id="text-alasan"
                            :class="{
                                'input-invalid': errorsInput.description
                            }"
                        ></textarea>
                        <div
                            v-show="errorsInput.description"
                            class="invalid-error"
                        >
                            Alasan harus diisi
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group row">
                    <label class="col-4 label-form-doctor form-label">
                        Dokter
                    </label>
                    <div class="col-8 nama-dokter">
                        <p style="margin-bottom: 0;">
                            {{ viewData.nama | strip }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4 label-form-doctor form-label">
                        Hari
                    </label>
                    <div class="col-8 nama-dokter">
                        <p style="margin-bottom: 0;">
                            {{ viewData.day | strip }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4 label-form-doctor form-label">
                        Jam Mulai
                    </label>
                    <div class="col-8 nama-dokter">
                        <p style="margin-bottom: 0;">
                            {{ viewData.jamMulai | strip }}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-4 label-form-doctor form-label">
                        Jam Selesai
                    </label>
                    <div class="col-8 nama-dokter">
                        <p style="margin-bottom: 0;">
                            {{ viewData.jamSelesai | strip }}
                        </p>
                    </div>
                </div>
            </form>
        </ModalBody>
        <div
            class="form-divider"
            style="margin-left: 20px;margin-right: 20px;"
        ></div>
        <ModalFooter class="custom-footer">
            <div>
                <button
                    @click.prevent="saveData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div></ModalFooter
        >
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import DatePicker from "../../../_general/DatePicker.vue";
export default {
    name: "AbsenceModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        DatePicker
    },
    mixins: [ModalMixin],
    data() {
        return {
            modalTitle: "Ketidakhadiran Dokter",
            absenceData: {
                tanggal: null,
                description: null
            },
            viewData: {
                name: null,
                day: null,
                jamMulai: null,
                jamSelesai: null
            },
            errorsInput: {
                tanggal: false,
                description: false
            },
            daysDisabled: null
        };
    },
    filters: {
        strip(str) {
            let text = ``;
            if (str) {
                text = str;
            }
            return text;
        }
    },
    methods: {
        saveData() {
            if (!this.absenceData.tanggal) {
                this.errorsInput.tanggal = true;
            } else {
                this.errorsInput.tanggal = false;
            }
            if (!this.absenceData.description) {
                this.errorsInput.description = true;
            } else {
                this.errorsInput.description = false;
            }
            let isError = false;
            Object.keys(this.errorsInput).forEach(key => {
                if (this.errorsInput[key]) {
                    isError = true;
                }
            });
            if (this.componentData && !isError) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(this.absenceData);
                }
            }
        },
        initDaysDisabled(hari) {
            let dayList = {
                0: `Minggu`,
                1: `Senin`,
                2: `Selasa`,
                3: `Rabu`,
                4: `Kamis`,
                5: `Jumat`,
                6: `Sabtu`
            };
            let disabledDays = [];
            $.each(dayList, function(key, value) {
                if (hari != value) {
                    disabledDays.push(key);
                }
            });

            this.daysDisabled = disabledDays.join();
        }
    },
    watch: {
        "absenceData.tanggal": {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.errorsInput.tanggal = false;
                } else {
                    this.errorsInput.tanggal = true;
                }
            },
            deep: true
        },
        "absenceData.description": {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.errorsInput.description = false;
                } else {
                    this.errorsInput.description = true;
                }
            }
        }
    },
    created() {
        if (this.componentData) {
            if (this.componentData.initialData) {
                this.viewData = this.componentData.initialData.viewData;
                this.initDaysDisabled(
                    this.componentData.initialData.viewData.day
                );
            }
        }
    }
};
</script>

<style></style>
