<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.37768 7.2528C6.41832 7.21206 6.4666 7.17973 6.51975 7.15768C6.5729 7.13562 6.62988 7.12427 6.68743 7.12427C6.74497 7.12427 6.80195 7.13562 6.85511 7.15768C6.90826 7.17973 6.95654 7.21206 6.99718 7.2528L7.99993 8.25642L9.00268 7.2528C9.08483 7.17065 9.19625 7.1245 9.31243 7.1245C9.42861 7.1245 9.54003 7.17065 9.62218 7.2528C9.70433 7.33495 9.75048 7.44637 9.75048 7.56255C9.75048 7.67873 9.70433 7.79015 9.62218 7.8723L8.61855 8.87505L9.62218 9.8778C9.70433 9.95995 9.75048 10.0714 9.75048 10.1875C9.75048 10.3037 9.70433 10.4151 9.62218 10.4973C9.54003 10.5795 9.42861 10.6256 9.31243 10.6256C9.19625 10.6256 9.08483 10.5795 9.00268 10.4973L7.99993 9.49367L6.99718 10.4973C6.91503 10.5795 6.80361 10.6256 6.68743 10.6256C6.57125 10.6256 6.45983 10.5795 6.37768 10.4973C6.29553 10.4151 6.24937 10.3037 6.24937 10.1875C6.24937 10.0714 6.29553 9.95995 6.37768 9.8778L7.3813 8.87505L6.37768 7.8723C6.33693 7.83166 6.30461 7.78338 6.28255 7.73023C6.2605 7.67708 6.24915 7.6201 6.24915 7.56255C6.24915 7.505 6.2605 7.44802 6.28255 7.39487C6.30461 7.34172 6.33693 7.29344 6.37768 7.2528Z"
            :fill="strokeColor"
        />
        <path
            d="M4.0625 1C4.17853 1 4.28981 1.04609 4.37186 1.12814C4.45391 1.21019 4.5 1.32147 4.5 1.4375V1.875H11.5V1.4375C11.5 1.32147 11.5461 1.21019 11.6281 1.12814C11.7102 1.04609 11.8215 1 11.9375 1C12.0535 1 12.1648 1.04609 12.2469 1.12814C12.3289 1.21019 12.375 1.32147 12.375 1.4375V1.875H13.25C13.7141 1.875 14.1592 2.05937 14.4874 2.38756C14.8156 2.71575 15 3.16087 15 3.625V13.25C15 13.7141 14.8156 14.1592 14.4874 14.4874C14.1592 14.8156 13.7141 15 13.25 15H2.75C2.28587 15 1.84075 14.8156 1.51256 14.4874C1.18437 14.1592 1 13.7141 1 13.25V3.625C1 3.16087 1.18437 2.71575 1.51256 2.38756C1.84075 2.05937 2.28587 1.875 2.75 1.875H3.625V1.4375C3.625 1.32147 3.67109 1.21019 3.75314 1.12814C3.83519 1.04609 3.94647 1 4.0625 1V1ZM1.875 4.5V13.25C1.875 13.4821 1.96719 13.7046 2.13128 13.8687C2.29538 14.0328 2.51794 14.125 2.75 14.125H13.25C13.4821 14.125 13.7046 14.0328 13.8687 13.8687C14.0328 13.7046 14.125 13.4821 14.125 13.25V4.5H1.875Z"
            :fill="strokeColor"
        />
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

<style></style>
