<template>
    <table
        class="table table-striped- table-bordered table-hover table-checkable"
        v-bind:id="table.tableName"
    ></table>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
import Jadwal from "../../../model/jadwal-model";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    data() {
        return {
            jadwal: new Jadwal(),
            jadwalList: {},
            listSearch: []
        };
    },
    props: {
        table: { type: Object },
        doctorVue: { type: Object }
    },
    methods: {
        async getData(id) {
            blockUI.blockPage();
            const { data } = await DoctorRepository.getDoctor(id);
            blockUI.unblockPage();
            this.doctorVue.dataEdit = data;
            this.doctorVue.showModal();
        },
        async openScheduleModal(id) {
            blockUI.blockPage();
            const { data } = await DoctorRepository.getDoctor(id);
            blockUI.unblockPage();
            this.jadwal.dokter = {
                id: data.id,
                nama: data.nama
            };

            this.doctorVue.dataEditSchedule = this.jadwal;
            this.doctorVue.showModalSchedule();
            this.jadwal = new Jadwal();
        },
        async openModalLogin(id) {
            blockUI.blockPage();
            const { data } = await DoctorRepository.getDoctor(id);
            blockUI.unblockPage();
            this.doctorVue.dataEditLogin = data;
            this.doctorVue.showModalLogin();
        },
        searchTable(data) {
            this.$emit("change", data);
        },
        getDatatables: function() {
            var vx = this;
            var tableParams = this.table;
            $.fn.dataTable.Api.register("column().title()", function() {
                return $(this.header())
                    .text()
                    .trim();
            });

            var dtTables, settingsOInstance;

            dtTables = $("#" + tableParams.tableName).DataTable({
                responsive: !0,
                dom:
                    "<'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",
                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                paging: false,
                info: false,
                language: {
                    lengthMenu: "Menampilkan _MENU_",
                    info: "Menampilkan _START_ - _END_ dari _TOTAL_ hasil"
                },
                searchDelay: 500,
                processing: !0,
                serverSide: 0,
                data: tableParams.tableDataResult,
                columns: tableParams.tableColumns,
                initComplete: function(settings) {
                    var e = this;
                    settingsOInstance = settings.oInstance;
                    // Delete Doctor
                    $("#table_doctor").on(
                        "click",
                        "[data-delete-id]",
                        function() {
                            var del_id = $(this).attr("data-delete-id");
                            let instance = new SweetAlert().showConfirm({
                                swalTitle: "Apakah Anda yakin?",
                                swalText:
                                    "Data Dokter beserta Jadwalnya akan terhapus.",
                                swalType: "warning",
                                onConfirmButton: function() {
                                    blockUI.blockPage();
                                    DoctorRepository.deleteDoctor(del_id).then(
                                        response => {
                                            new Promise((resolve, reject) => {
                                                vx.doctorVue.fetch(resolve);
                                                resolve();
                                            }).then(response => {
                                                let instance = new SweetAlert().showInfo(
                                                    {
                                                        swalTitle: "Terhapus!",
                                                        swalText:
                                                            "Data Dokter beserta Jadwalnya berhasil terhapus.",
                                                        swalType: "success",
                                                        onConfirmButton: function() {
                                                            blockUI.unblockPage();
                                                        }
                                                    }
                                                );
                                            });
                                        },
                                        error => {
                                            swal(
                                                "Gagal!",
                                                "Data Dokter beserta Jadwalnya tidak berhasil dihapus.",
                                                "error"
                                            );
                                        }
                                    );
                                },
                                onCancelButton: function() {
                                    blockUI.unblockPage();
                                }
                            });
                        }
                    );

                    // Check which button is clicked
                    $("#table_doctor").on(
                        "click",
                        "[data-edit-id]",
                        function() {
                            var attr = $(this).attr("data-edit-id");
                            var doctor_id = $(this).attr("data-edit-id");

                            vx.getData(doctor_id);
                        }
                    );
                    $("#table_doctor").on(
                        "click",
                        "[data-login-id]",
                        function() {
                            var attr = $(this).attr("data-login-id");
                            var doctor_id = $(this).attr("data-login-id");

                            vx.openModalLogin(doctor_id);
                        }
                    );

                    $("#table_doctor").on(
                        "click",
                        "[data-schedule-id]",
                        function() {
                            var attr = $(this).attr("data-schedule-id");
                            var doctor_id = $(this).attr("data-schedule-id");

                            vx.openScheduleModal(doctor_id);
                        }
                    );
                },
                drawCallback: function(settings) {
                    var api = this.api();
                    var rows = api
                        .rows({
                            page: "all"
                        })
                        .nodes();
                    var last = null;

                    api.column(0, {
                        page: "all"
                    })
                        .data()
                        .order([1, "asc"])
                        .each(function(group, i) {
                            if (last !== group) {
                                var showEllipsis = "";

                                var showEdit = "";
                                var showSchedule = "";
                                var showDelete = "";
                                var showLogin = "";
                                var showUserIcon = "";
                                var showPublishedIcon = "";

                                if (
                                    vx.checkPermission(
                                        "DokterResource.PUT.Dokter"
                                    )
                                ) {
                                    showEdit =
                                        '<button data-edit-id="' +
                                        api.column(0).data()[i] +
                                        '" class="dropdown-item"\
                            >\
                            <span class="mr-2"><i class="la la-edit"></i></span>\
                          <span>Edit Dokter</span>\
                    </button >';
                                }

                                if (
                                    vx.checkPermission(
                                        "JadwalResource.POST.Jadwal"
                                    )
                                ) {
                                    showSchedule =
                                        '<button data-schedule-id="' +
                                        api.column(0).data()[i] +
                                        '" class="dropdown-item"\
                            >\
                            <span class="mr-2"><i class="la la-plus"></i></span>\
                          <span>Tambah Jadwal</span>\
                    </button >';
                                }

                                if (
                                    vx.checkPermission(
                                        "DokterResource.PUT.Dokter"
                                    )
                                ) {
                                    showLogin =
                                        '<button data-login-id="' +
                                        api.column(0).data()[i] +
                                        '" class="dropdown-item"\
                            >\
                            <span class="mr-2"><i class="la la-user"></i></span>\
                          <span>Dokter Login</span>\
                    </button >';
                                }
                                if (
                                    vx.checkPermission(
                                        "DokterResource.DELETE.Dokter.id"
                                    )
                                ) {
                                    showDelete =
                                        '<button data-delete-id="' +
                                        api.column(0).data()[i] +
                                        '" class="dropdown-item"\
                            >\
                            <span class="mr-2"><i class="la la-trash"></i></span>\
                          <span>Hapus Dokter</span>\
                    </button >';
                                }

                                if (api.column(10).data()[i]) {
                                    showUserIcon =
                                        '<i class="fa fa-user-lock float-right my-2 font-weight-bold user-id"></i>';
                                }
                                if (api.column(11).data()[i]) {
                                    showPublishedIcon =
                                        "<span class='publish-span m-2 float-right font-weight-bold' > published </span>";
                                }

                                showEllipsis =
                                    showEdit +
                                    showSchedule +
                                    showLogin +
                                    showDelete;
                                $(rows)
                                    .eq(i)
                                    .before(
                                        '<tr class="group">\
                      <td colspan="8"><i class="fa fa-user-md"></i>' +
                                            ' <span class="text-bold">' +
                                            api.column(0).data()[i] +
                                            "</span>&nbsp;-&nbsp;" +
                                            api.column(1).data()[i] +
                                            '<small class="text-muted"> &nbsp;&nbsp' +
                                            api.column(2).data()[i] +
                                            '</small>\
                    <div class="dropdown d-inline">\
                    <button\
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"\
                    data-toggle="dropdown"\
                              >\
                              <i class="la la-ellipsis-h font-weight-bold"></i>\
                  </button >\
                    <div class="dropdown-menu dropdown-menu-left">\
                      <h6 class="dropdown-header">Option</h6>' +
                                            showEllipsis +
                                            "</div>" +
                                            showUserIcon +
                                            showPublishedIcon +
                                            "</div >\
                      </td >\
                      </tr >"
                                    );
                                last = group;
                            }
                            if (!api.column(4).data()[i]) {
                                $(rows)
                                    .eq(i)
                                    .hide();
                            }
                        });
                },
                columnDefs: tableParams.tableColumnDefs
            });

            vx.dataTableFilter(dtTables, settingsOInstance);
        },
        dataTableFilter: function(t, e) {
            var vx = this;
            var tableParams = this.table;
            var a = $('<tr class="filter"></tr>').appendTo(
                $(t.table().header())
            );
            e.api()
                .columns()
                .every(function() {
                    var fx = this;

                    var e;

                    var column_type;
                    var column_type2 = _.first(
                        _.filter(tableParams.tableFilter, function(data) {
                            return data.title == fx.title();
                        })
                    );

                    if (column_type2 != undefined) {
                        column_type = column_type2.type;
                    }

                    switch (column_type) {
                        case "text":
                            e = $(
                                '<input type="text" class="form-control form-control-sm form-filter kt-input" data-col-index="' +
                                    this.index() +
                                    '"/>'
                            );
                            break;
                        case "combobox":
                            (e = $(
                                '<select class="form-control form-control-sm form-filter kt-input" title="Select" data-col-index="' +
                                    this.index() +
                                    '"><option value="">Semua</option></select>'
                            )),
                                this.data()
                                    .unique()
                                    .sort()
                                    .each(function(t, a) {
                                        $(e).append(
                                            '<option value="' +
                                                t +
                                                '">' +
                                                t +
                                                "</option>"
                                        );
                                    });
                            break;
                        case "datepicker_today":
                            e = $(
                                '<div class="input-group input-group-sm date">\
                                                     <div class="input-group-prepend"><span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span></div>\
                                                     <input type="text" class="form-control form-control-sm form-filter kt-input datepicker-today-filter" data-col-index="' +
                                    this.index() +
                                    '">\
                                                     </div>'
                            );
                            break;
                        case "datepicker":
                            e = $(
                                '<div class="input-group input-group-sm date">\
                                                     <div class="input-group-prepend"><span class="input-group-text"><i class="la la-calendar-o glyphicon-th"></i></span></div>\
                                                     <input type="text" class="form-control form-control-sm form-filter kt-input datepicker-filter" data-col-index="' +
                                    this.index() +
                                    '">\
                                                     </div>'
                            );
                            break;
                        case "actions":
                            var i = $(
                                    '<div class="btn-group mr-2 d-none" role="group">\
                                                     <button id="filter_search" class="btn btn-brand kt-btn btn-sm kt-btn--icon" title="Search"><span><i class="la la-search"></i></span></button>\
                                                     </div>'
                                ),
                                s = $(
                                    '<div class="btn-group d-none" role="group">\
                                                         <button class="btn btn-secondary kt-btn btn-sm kt-btn--icon" title="Reset"><span><i class="la la-close"></i></span></button>\
                                                         </div>'
                                );
                            $("<th>")
                                .append(i)
                                .append(s)
                                .appendTo(a),
                                $(i).on("click", function(e) {
                                    e.preventDefault();
                                    var n = {};
                                    vx.listSearch = [];
                                    $(a)
                                        .find(".kt-input")
                                        .each(function() {
                                            var t = $(this).data("col-index");
                                            n[t]
                                                ? (n[t] += "|" + $(this).val())
                                                : (n[t] = $(this).val());
                                        }),
                                        $.each(n, function(e, a) {
                                            if (a) {
                                                vx.listSearch.push({ e, a });
                                            }
                                            // if(e != 6 && e != 7) t.column(e).search(a || "", !1, !1);
                                        });
                                    if (vx.listSearch.length > 0) {
                                        vx.searchTable(vx.listSearch);
                                    } else {
                                        vx.searchTable([]);
                                    }
                                    // t.table().draw();
                                });
                        // $(s).on("click", function (e) {
                        //   e.preventDefault(),
                        //     $(a)
                        //       .find(".kt-input")
                        //       .each(function (e) {
                        //         $(this).val("")
                        //           t
                        //             .column($(this).data("col-index"))
                        //             // .search("", !1, !1);
                        //       })
                        //     // t.table().draw();
                        // });
                    }
                    "Actions" !== this.title() &&
                        $(e).appendTo($("<th>").appendTo(a));
                });
            var searchTimeOut;
            var n = function() {
                e.api()
                    .columns()
                    .every(function() {
                        this.responsiveHidden()
                            ? $(a)
                                  .find("th")
                                  .eq(this.index())
                                  .show()
                            : $(a)
                                  .find("th")
                                  .eq(this.index())
                                  .hide();
                    });
            };
            n(),
                (window.onresize = n),
                // Filter
                $(".kt-input").bind("keyup change", function() {
                    if (searchTimeOut) {
                        clearTimeout(searchTimeOut);
                    }
                    searchTimeOut = setTimeout(() => {
                        $("#filter_search").trigger("click");
                        const kt = $("#head-toolbar");
                        const add = '<input type="text" id="null-data">';
                        kt.append(add);
                        $("#null-data").focus();
                        $("#null-data").remove();
                        if (!$(this).hasClass("datepicker-today-filter"))
                            $(this).focus();
                    }, 500);
                }),
                $(".datepicker-today-filter")
                    .datepicker({
                        format: "dd/mm/yyyy"
                    })
                    .datepicker("setDate", "now"),
                $("#filter_search").trigger("click"),
                $(".datepicker-filter").datepicker({
                    format: "dd/mm/yyyy"
                });
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        }
    },
    mounted() {
        this.getDatatables();
    }
};
</script>
