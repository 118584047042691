<template>
    <div class="selected-multi" style="width: 100%;">
        <SelectedText
            :numberIndex="numberIndex"
            :nama="dataOption.nama"
            :parentNumber="parentNumber"
        >
        </SelectedText>
        <div class="button-multi d-flex">
            <div
                class="radio-check"
                style="width:47px;display: flex;justify-content: center;margin-right: 10px;padding-bottom: 10px;"
            >
                <input
                    v-model="optionsData.isDefault"
                    type="checkbox"
                    name=""
                    id=""
                    :disabled="canSelect"
                    style="accent-color: #23415B;width: 20px;"
                />
            </div>
            <div
                style="width:47px;display: flex;justify-content: center;margin-right: 10px;"
            >
                <SquaredEye
                    :isPublish="optionsData.isPublish"
                    @squared-eye="selectSelected(numberIndex, propertyName)"
                >
                </SquaredEye>
            </div>
            <div style="width:47px;display: flex;justify-content: center;">
                <TrashButton
                    @delete-emit="deleteSelected(numberIndex, propertyName)"
                >
                </TrashButton>
            </div>
        </div>
    </div>
</template>

<script>
// import Multiselect from "vue-multiselect";
import EyeFillIcon from "../../_icons/EyeFillIcon.vue";
import EyeIconSlashed from "../../_icons/EyeIconSlashed.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";
import SelectedText from "../../_base/SelectedText.vue";
import SquaredEye from "../../_base/SquaredEye.vue";
import TrashButton from "../../_base/TrashButton.vue";

export default {
    name: "SelectedOption",
    components: {
        // Multiselect,
        EyeFillIcon,
        EyeIconSlashed,
        TrashIcon,
        SelectedText,
        SquaredEye,
        TrashButton
    },
    props: {
        dataOption: {
            type: Object,
            default: () => {
                return {
                    id: 2322,
                    nama: "sesuatu yang dipilih",
                    isDefault: false,
                    isPublish: false
                };
            }
        },
        numberIndex: {
            type: [Number, String],
            default: 1
        },
        canSelect: {
            type: Boolean,
            default: false
        },
        propertyName: {
            type: String,
            default: ""
        },
        parentNumber: {
            type: [String, Number]
        }
    },
    data() {
        return {
            optionsData: {
                isDefault: false,
                isPublish: false
            }
        };
    },
    watch: {
        dataOption: {
            handler(newVal, oldVal) {
                this.optionsData.isDefault = newVal.isDefault;
                this.optionsData.isPublish = newVal.isPublish;
            },
            deep: true
        },
        "optionsData.isDefault": {
            handler(newVal, oldVal) {
                // console.log(newVal, "defaultChange");
                this.$emit("update:defaultChange", newVal);
            }
        }
    },
    methods: {
        deleteSelected(numberIndex, propertyName) {
            this.$emit("delete-option", {
                index: numberIndex,
                propertyName: propertyName
            });
        },
        selectSelected(numberIndex, propertyName) {
            this.$emit("select-selected", {
                index: numberIndex,
                propertyName: propertyName
            });
        }
    },
    created() {
        this.optionsData.isDefault = this.dataOption.isDefault;
        this.optionsData.isPublish = this.dataOption.isPublish;
    }
};
</script>

<style></style>
