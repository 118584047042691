<template>
    <b-dropdown
        id="dropdown-left"
        variant="primary"
        right
        class="m-2 custom-dropdown dropdown-redesign"
    >
        <!-- style="height: 20%;" -->
        <template #button-content>
            <EllipsisV></EllipsisV>
        </template>
        <slot name="dropdown-item"></slot>
    </b-dropdown>
</template>

<script>
import EllipsisV from "../../_icons/EllipsisV.vue";
export default {
    components: {
        EllipsisV
    }
};
</script>

<style></style>
