<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.625 2.75C3.625 2.28587 3.80937 1.84075 4.13756 1.51256C4.46575 1.18437 4.91087 1 5.375 1H10.625C11.0891 1 11.5342 1.18437 11.8624 1.51256C12.1906 1.84075 12.375 2.28587 12.375 2.75V13.25C12.375 13.7141 12.1906 14.1592 11.8624 14.4874C11.5342 14.8156 11.0891 15 10.625 15H5.375C4.91087 15 4.46575 14.8156 4.13756 14.4874C3.80937 14.1592 3.625 13.7141 3.625 13.25V2.75ZM8.875 12.375C8.875 12.1429 8.78281 11.9204 8.61872 11.7563C8.45462 11.5922 8.23206 11.5 8 11.5C7.76794 11.5 7.54538 11.5922 7.38128 11.7563C7.21719 11.9204 7.125 12.1429 7.125 12.375C7.125 12.6071 7.21719 12.8296 7.38128 12.9937C7.54538 13.1578 7.76794 13.25 8 13.25C8.23206 13.25 8.45462 13.1578 8.61872 12.9937C8.78281 12.8296 8.875 12.6071 8.875 12.375Z"
            :fill="strokeColor"
        />
    </svg>
</template>
<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>
