<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
         <!-- <form-modal-login
                v-if="isShowModalLogin"
                :show-modal.sync="isShowModalLogin"
                :modal-name="modalNameLogin"
                :modal-data="dataEditLogin"
            ></form-modal-login> -->
             <modal-show-link :link-data="linkDokter" v-if="isShowLink" @setCloseModal="linkClose"/>
        <div
            class="kt-portlet kt-portlet--mobile head-container-kt"
            style="min-height: 80vh;"
        >
            <div class="kt-portlet__head kt-portlet__head--lg header-container">
                <base-title
                    class="head-label"
                    v-bind:head-title="'Master Dokter'"
                ></base-title>
                <div class="d-flex">
                    <button
                        :disabled="isSyncDataLoading || !isLoaded"
                        @click.prevent="syncData"
                        type="button"
                        class="refresh-button sync-button"
                    >
                        <div>
                            <SyncIcon
                                :strokeColor="
                                    isSyncDataLoading || !isLoaded
                                        ? '#c1c1c1'
                                        : '#23415B'
                                "
                            ></SyncIcon>
                        </div>
                    </button>
                    <button
                        :disabled="isFetching || !isLoaded"
                        @click.prevent="refreshData"
                        type="button"
                        class="refresh-button"
                    >
                        <div>
                            <RefreshIcon
                                :strokeColor="
                                    isFetching ? '#c1c1c1' : '#23415B'
                                "
                                style="width:14px;"
                            ></RefreshIcon>
                        </div>
                    </button>
                    <button
                        v-if="permissionObject.isCanCreateDoctor"
                        :disabled="!isLoaded"
                        class="btn btn-primary custom-modal-button"
                        style="padding: 0 24px;"
                        @click="openAddDoctorModal"
                    >
                        <p>Tambah Dokter</p>
                        <i class="flaticon2-add-1"></i>
                        <!-- <AddIcon :strokeColor="'#ffffff'" /> -->
                    </button>
                </div>
            </div>
            <div v-if="isLoaded" class="kt-portlet__body body-mobile">
                <div
                    class="d-flex justify-content-between"
                    style="margin-top: 20px;"
                >
                    <SortButton
                        :isDisable="isFetching"
                        :changeSortMethod="changeSort"
                        :isAsc="isAsc"
                    >
                        <p
                            v-if="!isFetching"
                            slot="text"
                            style="margin-top: 12%;"
                        >
                            Nama Dokter
                        </p>
                        <p v-else slot="text" style="margin-top: 12%;">
                            Loading...
                        </p>
                    </SortButton>

                    <SearchInput
                        :initialSearch="initialSearch"
                        :isRenderComponent="isRenderComponent"
                        @search-submit="searchSubmit"
                    ></SearchInput>

                    <FilterButton
                        :activeFilter="activeFilter()"
                        :clickedMethod="openFilterModal"
                    ></FilterButton>
                </div>
                <div v-if="activeFilter()" class="selected-filter">
                    <FilterItems
                        :isFetching="isFetching"
                        :filterList="filterItemData()"
                        :clickedMethod="deleteIndvFilter"
                    ></FilterItems>

                    <a
                        style="cursor: pointer;"
                        v-if="!isFetching"
                        @click="deleteAllFilter"
                    >
                        Hapus Semua Filter</a
                    >
                </div>
                <div
                    style="display:flex;justify-content:right;margin: 30px 0 30px 0;"
                >
                    <Pagination
                        v-if="cardData.length > 0"
                        :page_size="+selectedRecord"
                        :total-list="totalItems"
                        :page="currentPage"
                        @change="changePage"
                        class="custom-pagination doctor-new-pagination"
                    />
                    <div
                        class="record-limit"
                        style="display: flex;flex-direction: row-reverse;align-items: center;margin-bottom: 15px;"
                    >
                        <select
                            @change="
                                $emit(
                                    `update:record-limit`,
                                    $event.target.value
                                )
                            "
                            name="limit"
                        >
                            <option
                                v-for="(value, index) in recordValues"
                                :key="index"
                                :value="value"
                                >{{ value }}</option
                            >
                        </select>
                        <label>Menampilkan :</label>
                    </div>
                </div>
                <div class="doctor-card-container">
                    <DoctorCard
                        v-for="(data, index) in cardData"
                        :key="index"
                        :konsultasiIcons="
                            data.doctorCardChipIcon.konsultasiIcons
                        "
                        :bookingIcons="data.doctorCardChipIcon.bookingIcons"
                        :doctorCardData="data.doctorCardData"
                    >
                        <TableAccordion
                            slot="card-footer"
                            slot-scope="{ jadwal, activeDays }"
                            :tableData="jadwal"
                            :daysData="activeDays"
                            :accordionId="collapseToggle[index].id"
                            :isJustShown="collapseToggle[index].isJustShown"
                        ></TableAccordion>
                        <EllipsisDropdown
                            v-if="
                                permissionObject.isCanDeleteDoctor ||
                                    permissionObject.isCanSeeDetailDoctor
                            "
                            slot="action-button"
                        >
                            <OptionDetail
                                v-if="permissionObject.isCanSeeDetailDoctor"
                                slot="dropdown-item"
                                @see-detail="
                                    seeDetail(data.doctorCardData.doctorId)
                                "
                            />
                             <!-- <option-dokter-login
                                v-if="permissionObject.isCanDokterLogin"
                                @dokter-login="setDataDokterLogin(
                                    data
                                )"
                                slot="dropdown-item"
                            /> -->
                            <OptionDelete
                                v-if="permissionObject.isCanDeleteDoctor"
                                @delete-data="
                                    deleteDokterConfirmation({
                                        nama: data.doctorCardData.doctorName,
                                        id: data.doctorCardData.doctorId
                                    })
                                "
                                slot="dropdown-item"
                            />
                            <option-link-button
                            v-if="permissionObject.isCanLinkDokter"
                                slot="dropdown-item"
                                :text-str="'Akses Dokter'"
                                @link-button="
                                    linkButton(data.doctorCardData.doctorId, data.doctorCardData.tenant_id)
                                "
                            />
                        </EllipsisDropdown>
                    </DoctorCard>
                    <div
                        class="d-flex"
                        style="justify-content:center;"
                        v-if="!cardData.length"
                    >
                        <div
                            class="custom-header doctor-id"
                            style="display: flex;
    flex-direction: column;
    align-items: center;"
                        >
                            <img :src="noResult" alt="" />
                            <h3>Data Dokter Tidak Ditemukan</h3>
                            <p style="margin:0;">
                                Silakan cek dan atur ulang filter yang Anda
                                terapkan
                            </p>
                            <p style="margin:0;">atau</p>
                            <p style="margin:0;">
                                Lakukan sync data dan cari ulang
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <Pagination
                        v-if="cardData.length > 0"
                        :page_size="+selectedRecord"
                        :total-list="totalItems"
                        :page="currentPage"
                        class="custom-pagination hide-dots-pagination"
                        @change="changePage"
                    />
                </div>
            </div>
            <div v-else class="m-auto p-5 d-flex flex-row h-100">
                <div
                    class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                ></div>
                <h5 class="my-auto pl-5">Loading ...</h5>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../_base/BaseTitle";
import SortButton from "../_base/SortButton.vue";
import SearchInput from "../_base/SearchInput.vue";
import FilterButton from "../_base/FilterButton.vue";
import FilterItems from "../_base/FilterItems.vue";
import DoctorCard from "./components/DoctorCard.vue";
import TableAccordion from "./components/TableAccordion.vue";
import EllipsisDropdown from "./components/EllipsisDropdown.vue";

import OptionDetail from "./components/OptionSeeDetailButton.vue";
import OptionDelete from "./components/OptionDeleteButton.vue";
import OptionLinkButton from "./components/OptionLinkButton.vue";
// import OptionDokterLogin from "./components/OptionDokterLoginButton.vue";
import AddIcon from "../_icons/AddIcon.vue";
import RefreshIcon from "../_icons/RefreshIcon.vue";
import SyncIcon from "../_icons/SyncIcon.vue";

import noResultImage from "./assets/EmptyResult.png";

import Pagination from "../doctors/_components/Pagination.vue";
import ModalShowLink from './components/DoctorModal/ModalShowLink.vue'

import BlockUI from "../_js/BlockUI";
// import LoadSpinner from "./../_general/LoadSpinner";
const blockUI = new BlockUI();
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
export default {
    name: "MasterDoctorScreen",
    components: {
        BaseTitle,
        SortButton,
        SearchInput,
        FilterButton,
        FilterItems,
        AddIcon,
        DoctorCard,
        TableAccordion,
        RefreshIcon,
        Pagination,
        EllipsisDropdown,
        OptionDetail,
        OptionDelete,
        // OptionDokterLogin,
        SyncIcon,
        OptionLinkButton,
        ModalShowLink
        // FormModalLogin: () => ({
        //     component: new Promise(function(resolve, reject) {
        //         setTimeout(function() {
        //             resolve(
        //                 import("../doctors/_components/DoctorLogin.vue")
        //             );
        //         }, 1000);
        //     }),
        //     loading: LoadSpinner
        // }),
    },
    data() {
        return {
            collapseToggle: [],
            noResult: noResultImage,
            // isShowModalLogin: false,
            dataEditLogin: null,
            modalNameLogin: "modal_login"
        };
    },
    computed: {
        isShowLink () {
            return this.linkData.modal
        },
        linkDokter () {
            return {
               link: this.linkData.link,
               userId: this.linkData.userId
            }
        }
    },
    props: {
        cardData: {
            type: Array,
            default: () => []
        },
        recordValues: {
            type: Array
        },
        selectedRecord: {
            type: [Number, String]
        },
        currentPage: {
            type: [Number, String]
        },
        methodObject: {
            type: Object
        },
        isLoaded: {
            type: Boolean
        },
        filterList: {
            type: Object
        },
        totalItems: {
            type: [Number, String]
        },
        isFetching: {
            type: Boolean
        },
        isAsc: {
            type: Boolean
        },
        isRenderComponent: {
            type: Boolean
        },
        permissionObject: {
            type: Object
        },
        isSyncDataLoading: {
            type: Boolean
        },
        initialSearch: {
            type: String
        },
        linkData: {
            type: Object,
            default: () => {
                return {
                    modal: false,
                    link: ''
                }
            }
        }
    },
    watch: {
        // isShowModalLogin: function(val) {
        //     if (!val) {
        //         this.dataEditLogin = null;
        //     }
        // },
        cardData: {
            handler(e) {
                let collapseToggleNew = [];
                for (let i = 0; i < e.length; i++) {
                    collapseToggleNew.push({
                        id: `doctor-accordion-${i}`,
                        isJustShown: false
                    });
                }
                this.collapseToggle = collapseToggleNew;
            },
            deep: true
        }
    },
    created() {
        this.cardData.forEach((el, index) => {
            this.collapseToggle.push({
                id: `doctor-accordion-${index}`,
                isJustShown: false
            });
        });
    },
    mounted() {
        this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
            this.collapseToggle.find(el => {
                if (el.id == collapseId) {
                    el.isJustShown = isJustShown;
                }
            });
        });
    },
    methods: {
        linkClose () {
            if (this.methodObject) {
                if (typeof this.methodObject.closeModal == "function") {
                    this.methodObject.closeModal(false);
                }
            }
        },
        linkButton(id, tenant_id) {
             if (this.methodObject) {
                if (typeof this.methodObject.linkButton == "function") {
                    this.methodObject.linkButton(id, tenant_id);
                }
            }
        },
        // async setDataDokterLogin (item) {
        //     // event.stopPropagation()
        //     const { data } = await DoctorRepository.getDoctor(item.doctorCardData.doctorId);
        //     blockUI.unblockPage();
        //     this.dataEditLogin = data;
        //     this.showModalLogin();
        // },
        // showModalLogin: function() {
        //     this.isShowModalLogin = true;
        // },
        changeSort() {
            if (this.methodObject) {
                if (typeof this.methodObject.changeSortMethod == "function") {
                    this.methodObject.changeSortMethod();
                }
            }
        },
        openFilterModal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openModalFilterMethod == "function"
                ) {
                    this.methodObject.openModalFilterMethod();
                }
            }
        },
        seeDetail(id) {
            this.$router.push({
                name: "dokter-new-detail",
                params: { id: id }
            });
        },
        searchSubmit(data) {
            if (this.methodObject) {
                if (typeof this.methodObject.searchSubmitMethod == "function") {
                    this.methodObject.searchSubmitMethod(data);
                }
            }
        },
        openAddDoctorModal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.opemModalAddDoctorMethod ==
                    "function"
                ) {
                    this.methodObject.opemModalAddDoctorMethod();
                }
            }
        },
        filterItemData() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.filterItemsDataMethod == "function"
                ) {
                    return this.methodObject.filterItemsDataMethod();
                }
            }
        },
        deleteIndvFilter(payload) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteIndvFIlterMethod ==
                    "function"
                ) {
                    this.methodObject.deleteIndvFIlterMethod(payload);
                }
            }
        },
        deleteAllFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteAllFilterMethod == "function"
                ) {
                    this.methodObject.deleteAllFilterMethod();
                }
            }
        },
        refreshData() {
            if (this.methodObject) {
                if (typeof this.methodObject.refreshDataMethod == "function") {
                    this.methodObject.refreshDataMethod();
                }
            }
        },
        syncData() {
            if (this.methodObject) {
                if (typeof this.methodObject.syncDataMethod == "function") {
                    this.methodObject.syncDataMethod();
                }
            }
        },
        deleteDokterConfirmation(payload) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteDokterConfirmationMethod ==
                    "function"
                ) {
                    this.methodObject.deleteDokterConfirmationMethod(
                        payload,
                        "Data dokter akan terhapus secara permanen",
                        "Apakah Anda yakin ingin menghapus data dokter berikut?"
                    );
                }
            }
        },
        activeFilter() {
            let activeNumber = 0;
            Object.keys(this.filterList).forEach(key => {
                if (key == "dateRange") {
                    if (this.filterList.dateRange) {
                        if (this.filterList.dateRange.start) {
                            activeNumber++;
                        }
                        if (this.filterList.dateRange.end) {
                            activeNumber++;
                        }
                    }
                } else if (key == "tipeTatapMuka" || key == "tipeTelekonsul") {
                    if (
                        this.filterList[key] !== null ||
                        this.filterList[key] !== undefined
                    ) {
                        activeNumber++;
                    }
                } else {
                    if (
                        this.filterList[key].length > 0 &&
                        typeof this.filterList[key] !== "string"
                    ) {
                        activeNumber += this.filterList[key].length;
                    } else if (
                        typeof this.filterList[key] == "string" ||
                        typeof this.filterList[key] == "number"
                    ) {
                        activeNumber++;
                    }
                }
            });
            return activeNumber;
        },
        changePage(e) {
            if (this.methodObject) {
                if (typeof this.methodObject.changePageMethod == "function") {
                    this.methodObject.changePageMethod(e);
                }
            }
        }
    }
};
</script>

<style></style>
