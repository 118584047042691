<template>
    <div>
        <div class="inform-container">
            <div class="doctor-name" style="padding-left: 0;">
                <p>
                    {{ doctorName }}
                </p>
            </div>
            <div class="doctor-id d-flex">
                <p>ID:</p>
                <p ref="doctorId">{{ doctorId }}</p>
                <button class="copy-button" @click="copyURL">
                    <CopyIcon />
                </button>
            </div>
            <PublishChip
                :textString="isProfilePublished ? 'Published' : 'Unpublished'"
                :isPublished="isProfilePublished"
            ></PublishChip>
        </div>
        <div class="jenis-konsultasi">
            <InformationChips
                :classString="'konsultasi-jenis'"
                :informationTitle="'Jenis Konsultasi'"
                :icons="konsultasiIcons"
            />
            <InformationChips
                :classString="'booking-via'"
                :informationTitle="'Booking Via'"
                :icons="bookingIcons"
            />
        </div>
        <div class="doctor-spesialisasi">
            <div class="form-group row">
                <label class="col-lg-2 doctor-card-label">
                    Klinik
                </label>
                <div
                    style="overflow-y: hidden;overflow-x: auto;"
                    class="col-lg-6 d-flex"
                >
                    <p
                        class="plain-text"
                        v-for="(text, index) in klinik"
                        :key="index"
                    >
                        {{ text }}
                    </p>
                    <p class="plain-text" v-if="klinik.length == 0">-</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-2 doctor-card-label">Spesialisasi</label>
                <div
                    style="overflow-y: hidden;overflow-x: auto;"
                    class="col-lg-6 d-flex"
                >
                    <PublishChip
                        v-for="(spe, index) in spesialisasi"
                        :key="index"
                        :textString="spe.nama"
                        :isPublished="spe.isActive"
                    ></PublishChip>
                    <p class="plain-text" v-if="spesialisasi.length == 0">-</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-2 doctor-card-label">
                    Subspesialisasi
                </label>
                <div
                    style="overflow-y: hidden;overflow-x: auto;"
                    class="col-lg-6 d-flex"
                >
                    <PublishChip
                        v-for="(sub, index) in subSpesialisasi"
                        :key="index"
                        :textString="sub.nama"
                        :isPublished="sub.isActive"
                    ></PublishChip>
                    <p class="plain-text" v-if="subSpesialisasi.length == 0">
                        -
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CopyIcon from "../../_icons/CopyIcon.vue";
import PublishChip from "./PublishChips.vue";
import InformationChips from "./InformationChips.vue";
export default {
    components: {
        CopyIcon,
        PublishChip,
        InformationChips
    },
    props: {
        spesialisasi: {
            type: Array,
            default: () => []
        },
        subSpesialisasi: {
            type: Array,
            default: () => []
        },
        klinik: {
            type: Array,
            default: () => []
        },
        konsultasiIcons: {
            type: Array,
            default: () => []
        },
        bookingIcons: {
            type: Array,
            default: () => []
        },
        bahasaIcons: {
            type: Array,
            default: () => []
        },
        doctorId: {
            type: [String, Number],
            default: ""
        },
        doctorName: {
            type: String,
            default: ""
        },
        isProfilePublished: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async copyURL() {
            try {
                var doctorId = this.$refs.doctorId;
                console.log(doctorId.innerHTML, "doctorId");
                await navigator.clipboard.writeText(doctorId.innerHTML);
                toastr.info("Dokter ID Berhasil Di Salin");
            } catch (error) {
                toastr.error("Dokter ID Gagal Di Salin");
                console.log(error);
            }
        }
    }
};
</script>

<style></style>
