<template>
    <button
        @click.prevent="buttonClicked"
        class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
        :style="
            isPublish
                ? 'background:#EBF6F4 !important;'
                : 'background:#FFF2F2 !important;'
        "
    >
        <EyeFillIcon style="width:18px;" v-if="isPublish" />
        <EyeIconSlashed style="width:18px;" v-else />
    </button>
</template>

<script>
import EyeFillIcon from "../_icons/EyeFillIcon.vue";
import EyeIconSlashed from "../_icons/EyeIconSlashed.vue";
export default {
    name: "SquaredEye",
    props: {
        isPublish: {
            type: Boolean
        }
    },
    components: {
        EyeFillIcon,
        EyeIconSlashed
    },
    methods: {
        buttonClicked() {
            this.$emit("squared-eye");
        }
    }
};
</script>

<style></style>
