export default class PermissionClass {
    static isRenderByPermission(arrayPermission) {
        let permissionObject = {
            isCanCreateDoctor: false,
            isCanDeleteDoctor: false,
            isCanSeeDetailDoctor: false,
            isCanEditDoctor: false,
            isCanSeeProfileDetailDoctor: false,
            isCanCreateDoctorSchedule: false,
            isCanEditDoctorSchedule: false,
            isCanDeleteDoctorSchedule: false,
            isCanCreateDoctorAbsence: false,
            isCanSaveDataProfileMhg: false,
            isCandDokterLogin: false,
            isCanLinkDokter: false
        };
        let permissionName = {
            isCanCreateDoctor: "DoctorResource.POST.CreateDoctor",
            isCanDeleteDoctor: "DoctorResource.DEL.DeleteDoctor",
            isCanSeeDetailDoctor: "DoctorResource.GET.DoctorProfile",
            isCanEditDoctor: "DoctorResource.PUT.UpdateDoctor",
            isCanSeeProfileDetailDoctor: "DoctorResource.GET.DoctorDetail",
            isCanCreateDoctorSchedule: "DoctorResource.POST.DetailJadwal",
            isCanEditDoctorSchedule: "DoctorResource.PUT.DetailJadwal",
            isCanDeleteDoctorSchedule: "DoctorResource.DEL.DetailJadwal",
            isCanCreateDoctorAbsence: "DoctorResource.POST.DetailAbsence",
            isCanSaveDataProfileMhg: "DoctorResource.POST.ProfileMhg",
            isCanDokterLogin: 'DokterResource.PUT.Dokter',
            isCanLinkDokter: 'BackOffice.Option.Dokter.LinkButton'
        };

        if (arrayPermission) {
            if (Array.isArray(arrayPermission)) {
                Object.keys(permissionName).forEach(key => {
                    if (arrayPermission.includes(permissionName[key])) {
                        permissionObject[key] = true;
                    }
                });
            }
        }

        return permissionObject;
    }
}
