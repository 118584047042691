<template>
    <b-dropdown-item class="elipsis-item" @click.prevent="editData">
        <div style="width: 22px;">
            <EditIcon />
        </div>
        <p>
            Edit Dokter
        </p>
    </b-dropdown-item>
</template>

<script>
import EditIcon from "../../_icons/EditIcon.vue";
export default {
    components: {
        EditIcon
    },
    methods: {
        editData() {
            this.$emit("edit-data");
        }
    }
};
</script>

<style></style>
