<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_406_11862)">
            <path
                d="M10.625 1.875C10.8571 1.875 11.0796 1.96719 11.2437 2.13128C11.4078 2.29538 11.5 2.51794 11.5 2.75V13.25C11.5 13.4821 11.4078 13.7046 11.2437 13.8687C11.0796 14.0328 10.8571 14.125 10.625 14.125H5.375C5.14294 14.125 4.92038 14.0328 4.75628 13.8687C4.59219 13.7046 4.5 13.4821 4.5 13.25V2.75C4.5 2.51794 4.59219 2.29538 4.75628 2.13128C4.92038 1.96719 5.14294 1.875 5.375 1.875H10.625ZM5.375 1C4.91087 1 4.46575 1.18437 4.13756 1.51256C3.80937 1.84075 3.625 2.28587 3.625 2.75V13.25C3.625 13.7141 3.80937 14.1592 4.13756 14.4874C4.46575 14.8156 4.91087 15 5.375 15H10.625C11.0891 15 11.5342 14.8156 11.8624 14.4874C12.1906 14.1592 12.375 13.7141 12.375 13.25V2.75C12.375 2.28587 12.1906 1.84075 11.8624 1.51256C11.5342 1.18437 11.0891 1 10.625 1H5.375Z"
                fill="#23415B"
            />
            <path
                d="M8 13.25C8.23206 13.25 8.45462 13.1578 8.61872 12.9937C8.78281 12.8296 8.875 12.6071 8.875 12.375C8.875 12.1429 8.78281 11.9204 8.61872 11.7563C8.45462 11.5922 8.23206 11.5 8 11.5C7.76794 11.5 7.54538 11.5922 7.38128 11.7563C7.21719 11.9204 7.125 12.1429 7.125 12.375C7.125 12.6071 7.21719 12.8296 7.38128 12.9937C7.54538 13.1578 7.76794 13.25 8 13.25Z"
                fill="#23415B"
            />
        </g>
        <defs>
            <clipPath id="clip0_406_11862">
                <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="translate(1 1)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {};
</script>
