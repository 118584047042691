<template>
  <div class="text-center">
    <template v-if="checkPermission('JadwalResource.PUT.Jadwal')">
      <button
        @click="editJadwalDokter()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission('StatusJadwalDokterResource.POST.StatusJadwalDokter')">
      <button
        @click="cancelJadwalDokter()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Ketidakhadiran Dokter"
      >
        <i class="la la-calendar-times-o"></i>
      </button>
    </template>
    <template v-if="checkPermission('JadwalResource.DELETE.Jadwal.id')">
      <button
        @click="deleteJadwalDokter()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const ScheduleRepository = RepositoryFactory.get("schedule");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
  props: {
    jadwalVue: { type: Object },
    rowData: { type: Object }
  },
  methods: {
    deleteJadwalDokter() {
      var vx = this;
      let instance = new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: "Jadwal Dokter akan terhapus.",
        swalType: "warning",
        onConfirmButton: function () {
          blockUI.blockPage();
          vx.delete();
        },
        onCancelButton: function () {
          blockUI.unblockPage();
        }
      });
    },
    async delete() {
      var vx = this;

      await ScheduleRepository.deleteSchedule(this.rowData.id)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.jadwalVue.fetch(resolve);
            resolve()
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Jadwal Dokter berhasil terhapus.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Jadwal Dokter gagal terhapus.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockPage();
            }
          });
        });
    },
    editJadwalDokter() {
      blockUI.blockPage();
      ScheduleRepository.getSchedule(this.rowData.id)
        .then(res => {
          blockUI.unblockPage();
          this.jadwalVue.dataEditSchedule = res.data;
          this.jadwalVue.showModalSchedule();
        })
        .catch(err => {
          blockUI.unblockPage();
          this.showToastError('Tindakan : ' + err.error.response.data);
        })
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    cancelJadwalDokter() {
      blockUI.blockPage();
      ScheduleRepository.getSchedule(this.rowData.id)
        .then(res => {
          blockUI.unblockPage();
          this.jadwalVue.dataEditAbsent = res.data;
          this.jadwalVue.showModalAbsent();
        })
        .catch(err => {
          blockUI.unblockPage();
          this.showToastError('Tindakan : ' + err.error.response.data);
        })
    },
    showToastError: function (message) {
      toastr.error(message);
    },
  }
}
</script>

<style>
</style>
