<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.2 5C5.27956 5 5.35587 4.96839 5.41213 4.91213C5.46839 4.85587 5.5 4.77956 5.5 4.7V2.8C5.5 2.72044 5.53161 2.64413 5.58787 2.58787C5.64413 2.53161 5.72044 2.5 5.8 2.5H10.2C10.2394 2.5 10.2784 2.50776 10.3148 2.52284C10.3512 2.53791 10.3843 2.56001 10.4121 2.58787C10.44 2.61573 10.4621 2.6488 10.4772 2.68519C10.4922 2.72159 10.5 2.7606 10.5 2.8V4.7C10.5 4.77956 10.5316 4.85587 10.5879 4.91213C10.6441 4.96839 10.7204 5 10.8 5H11.7C11.7394 5 11.7784 5.00776 11.8148 5.02284C11.8512 5.03791 11.8843 5.06001 11.9121 5.08787C11.94 5.11573 11.9621 5.1488 11.9772 5.18519C11.9922 5.22159 12 5.2606 12 5.3V11.2C12 11.2394 11.9922 11.2784 11.9772 11.3148C11.9621 11.3512 11.94 11.3843 11.9121 11.4121C11.8843 11.44 11.8512 11.4621 11.8148 11.4772C11.7784 11.4922 11.7394 11.5 11.7 11.5H4.3C4.22044 11.5 4.14413 11.4684 4.08787 11.4121C4.03161 11.3559 4 11.2796 4 11.2V5.3C4 5.22044 4.03161 5.14413 4.08787 5.08787C4.14413 5.03161 4.22044 5 4.3 5H5.2V5Z"
            :stroke="strokeColor"
        />
        <path
            d="M8 9.505L8.005 9.4995M6.996 5H7.996H6.996ZM8.996 5H7.996H8.996ZM7.996 5V4V5ZM7.996 5V6V5ZM10 9.505L10.005 9.4995L10 9.505ZM10 7.505L10.005 7.4995L10 7.505ZM8 7.505L8.005 7.4995L8 7.505ZM6 7.505L6.005 7.4995L6 7.505ZM6 9.505L6.005 9.4995L6 9.505Z"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M2 13L14 2" :stroke="strokeColor" stroke-linecap="round" />
    </svg>
</template>
<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>
