<template>
    <Modal class="custom-modal">
        <ModalHeader class="custom-header">
            <h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody class="form-modal">
            <form style="position:relative;">
                <div v-if="isEdit" class="form-group ">
                    <label class=" label-form-doctor input-head">
                        ID
                    </label>
                    <div class="" style="margin-left: 12px;">
                        <p class="nama-dokter">
                            {{ dataJadwal.id | strip }}
                        </p>
                    </div>
                </div>
                <div class="form-group ">
                    <label class=" label-form-doctor input-head">
                        Dokter
                    </label>
                    <div class="" style="margin-left: 12px;">
                        <p class="nama-dokter">
                            {{ dataJadwal.dokter.id | strip }}-{{
                                dataJadwal.dokter.nama | strip
                            }}
                        </p>
                    </div>
                </div>
                <div class="row form-modal">
                    <div
                        class="form-group col-6"
                        style="border-right: 1px solid #CFDFF5;"
                    >
                        <label class=" label-form-doctor form-label">
                            {{ $t("common.hari")
                            }}<span style="color:red;">*</span>
                        </label>
                        <div class="">
                            <multiselect
                                :searchable="false"
                                class="holiday-multiselect"
                                :allow-empty="true"
                                :multiple="false"
                                :close-on-select="true"
                                v-model="dataJadwal.hari"
                                :options="daysOptions"
                                placeholder="Pilih Hari"
                                :class="{
                                    'input-invalid': errorInput.hari
                                }"
                            ></multiselect>
                            <div
                                v-show="errorInput.hari"
                                class="invalid-error "
                            >
                                Hari harus diisi
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class=" label-form-doctor form-label">
                            {{ $t("common.durationInMinute") }}
                            <span style="color:red;">*</span>
                        </label>
                        <div class="">
                            <VueInputNumber
                                class="custom-input-number"
                                :class="{
                                    'div-input-invalid':
                                        errorInput.durationInMinute
                                }"
                                v-model.number="dataJadwal.durationInMinute"
                                :name="'durasi'"
                                :placeholder="'Durasi Dalam Menit'"
                            >
                            </VueInputNumber>
                            <div
                                v-show="errorInput.durationInMinute"
                                class="invalid-error "
                            >
                                Durasi harus diisi
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-modal">
                    <div
                        class="form-group col-6"
                        style="border-right: 1px solid #CFDFF5;"
                    >
                        <label class=" label-form-doctor form-label">
                            {{ $t("common.jamMulai") }}
                            <span style="color:red;">*</span>
                        </label>
                        <div class="">
                            <JadwalTime
                                class="time-custom"
                                :class="{
                                    'div-input-invalid': errorInput.jamMulai
                                }"
                                :time-id="'jadwal_start_' + 'dokter_jadwal'"
                                :value="dataJadwal.jamMulai"
                                :changedValue.sync="dataJadwal.jamMulai"
                                :placeHolder="'Pilih jam mulai'"
                                :isNeedValidation="false"
                            >
                            </JadwalTime>
                            <div
                                v-show="errorInput.jamMulai"
                                class="invalid-error "
                            >
                                Jam Mulai harus diisi
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class=" label-form-doctor form-label">
                            {{ $t("common.jamSelesai") }}
                            <span style="color:red;">*</span>
                        </label>
                        <div class="">
                            <JadwalTime
                                class="time-custom"
                                :class="{
                                    'div-input-invalid': errorInput.jamSelesai
                                }"
                                :time-id="'jadwal_end_' + 'dokter_jadwal'"
                                :value="dataJadwal.jamSelesai"
                                :changedValue.sync="dataJadwal.jamSelesai"
                                :placeHolder="'Pilih jam selesai'"
                                :isNeedValidation="false"
                            >
                            </JadwalTime>
                            <div
                                v-show="errorInput.jamSelesai"
                                class="invalid-error "
                            >
                                Jam Selesai harus diisi
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row form-modal">
                    <div
                        class="form-group col-6"
                        style="border-right: 1px solid #CFDFF5;"
                    >
                        <label class="label-form-doctor form-label">
                            {{ $t("common.tanggalMulai") }}
                        </label>
                        <div class="">
                            <DatePicker
                                class="custom-datepicker"
                                :changedValue.sync="dataJadwal.tanggalMulai"
                                :id-date-picker="
                                    'jadwal_date_start_' + 'dokter_jadwal'
                                "
                                :picker-value="dataJadwal.tanggalMulai"
                                place-holder="Tanggal Mulai"
                                :is-validate="false"
                                start-date="0d"
                                end-date=""
                            >
                            </DatePicker>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class=" label-form-doctor form-label">
                            {{ $t("common.tanggalSelesai") }}
                        </label>
                        <div class="">
                            <DatePicker
                                class="custom-datepicker"
                                :changedValue.sync="dataJadwal.tanggalSelesai"
                                :id-date-picker="
                                    'jadwal_date_end_' + 'dokter_jadwal'
                                "
                                :picker-value="dataJadwal.tanggalSelesai"
                                place-holder="Tanggal Selesai"
                                :is-validate="false"
                                start-date="-1d"
                                end-date=""
                            >
                            </DatePicker>
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div>
                    <label
                        style="padding-left: 3px;"
                        class="col-4 label-form-doctor input-head"
                    >
                        Jadwal Di Blok
                    </label>

                    <MultiSelectJadwal
                        class="slot-jadwals"
                        v-model="dataJadwal.slotIndexBlocked"
                        :listJadwals="listJadwals"
                    >
                    </MultiSelectJadwal>
                    <p v-if="listJadwals.length == 0 && !isSlotJadwalNull">
                        Silakan tentukan durasi praktek terlebih dahulu
                    </p>
                    <p class="invalid-error" v-else-if="isSlotJadwalNull">
                        Silahkan Cek Durasi Jam Mulai & Jam Selesai
                    </p>
                </div>
                <div v-if="listJadwals.length > 0" class="form-divider"></div>
            </form>
        </ModalBody>
        <ModalFooter class="custom-footer footer-doctor">
            <div>
                <span>
                    <EyeFillIcon
                        style="width: 18px;"
                        v-if="dataJadwal.isPublish"
                    />
                    <EyeIconSlashed style="width: 18px;" v-else />Publish Jadwal
                </span>
                <label style="margin-top: 7px;" class="switch-publish">
                    <input v-model="dataJadwal.isPublish" type="checkbox" />
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    @click.prevent="saveData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";
import JadwalTime from "../../../_select/JadwalTime.vue";
import JadwalDay from "../../../_select/JadwalDay.vue";
import VueInputNumber from "../../../_general/VueInputNumber.vue";
import DatePicker from "../../../_general/DatePicker.vue";
import MultiSelectJadwal from "../../../doctors/_components/MultiSelectJadwal.vue";

import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";
import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";

import Multiselect from "vue-multiselect";
export default {
    name: "TambahJadwal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,

        JadwalTime,
        JadwalDay,
        VueInputNumber,
        DatePicker,

        EyeFillIcon,
        EyeIconSlashed,

        MultiSelectJadwal,

        Multiselect
    },
    mixins: [ModalMixin],
    data() {
        return {
            modalTitle: "Tambah Jadwal",
            dataJadwal: {
                id: null,
                dokter: {},
                hari: null,
                jamMulai: "",
                jamSelesai: "",
                durationInMinute: null,
                tanggalMulai: null,
                tanggalSelesai: null,
                slotIndexBlocked: [],
                isPublish: true
            },
            errorInput: {
                hari: false,
                durationInMinute: false,
                jamMulai: false,
                jamSelesai: false
            },
            daysOptions: [
                "Senin",
                "Selasa",
                "Rabu",
                "Kamis",
                "Jumat",
                "Sabtu",
                "Minggu"
            ],
            isEdit: false,
            isSlotJadwalNull: false,
            isMounted: false
        };
    },
    watch: {
        "dataJadwal.hari": {
            handler: function(val, oldVal) {
                if (!val) {
                    this.errorInput.hari = true;
                } else {
                    this.errorInput.hari = false;
                }
            },
            deep: true
        },
        "dataJadwal.durationInMinute": {
            handler: function(val, oldVal) {
                if (!val) {
                    this.errorInput.durationInMinute = true;
                } else {
                    if(oldVal){
                        this.dataJadwal.slotIndexBlocked = []
                    }
                    this.errorInput.durationInMinute = false;
                }
            },
            deep: true
        },
        "dataJadwal.jamMulai": {
            handler: function(val, oldVal) {
                if (!val) {
                    this.errorInput.jamMulai = true;
                } else {
                    if(oldVal){
                        this.dataJadwal.slotIndexBlocked = []
                    }
                    this.errorInput.jamMulai = false;
                }
            },
            deep: true
        },
        "dataJadwal.jamSelesai": {
            handler: function(val, oldVal) {
                if (!val) {
                    this.errorInput.jamSelesai = true;
                } else {
                    if(oldVal){
                        this.dataJadwal.slotIndexBlocked = []
                    }
                    this.errorInput.jamSelesai = false;
                }
            },
            deep: true
        }
    },
    computed: {
        listJadwals() {
            let slots = [];
            if (
                this.dataJadwal.jamMulai &&
                this.dataJadwal.jamSelesai &&
                this.dataJadwal.durationInMinute
            ) {
                slots = helpers.arraySlots(
                    this.dataJadwal.jamMulai,
                    this.dataJadwal.jamSelesai,
                    this.dataJadwal.durationInMinute
                );
                if (!slots.length) {
                    this.isSlotJadwalNull = true;
                } else {
                    this.isSlotJadwalNull = false;
                }
            }

            return slots;
        }
    },
    filters: {
        strip(str) {
            let text = ``;
            if (str) {
                text = str;
            }
            return text;
        }
    },
    mounted() {
        this.initData();
        this.isMounted = true
    },
    methods: {
        saveData() {
            if (!this.dataJadwal.hari) {
                this.errorInput.hari = true;
            }
            if (!this.dataJadwal.durationInMinute) {
                this.errorInput.durationInMinute = true;
            }
            if (!this.dataJadwal.jamMulai) {
                this.errorInput.jamMulai = true;
            }
            if (!this.dataJadwal.jamSelesai) {
                this.errorInput.jamSelesai = true;
            }
            if (!this.listJadwals.length) {
                this.isSlotJadwalNull = true;
            } else {
                this.isSlotJadwalNull = false;
            }
            let isError = false;
            Object.keys(this.errorInput).forEach(key => {
                if (this.errorInput[key]) {
                    isError = true;
                }
            });
            if (this.isSlotJadwalNull) {
                isError = true;
            }
            if (!isError) {
                if (this.componentData) {
                    if (this.componentData.action) {
                        if (typeof this.componentData.action == "function") {
                            this.componentData.action(this.dataJadwal);
                        }
                    }
                }
            }
        },
        dayToHari(day) {
            if (day) {
                let days = {
                    MONDAY: `Senin`,
                    TUESDAY: `Selasa`,
                    WEDNESDAY: `Rabu`,
                    THURSDAY: `Kamis`,
                    FRIDAY: `Jumat`,
                    SATURDAY: `Sabtu`,
                    SUNDAY: `Minggu`
                };
                if (days[day]) {
                    return days[day];
                }
            }
        },
        initData() {
            if (this.componentData) {
                if (this.componentData.initialData) {
                    if (this.componentData.initialData.dokter) {
                        this.dataJadwal.dokter = this.componentData.initialData.dokter;
                    }
                    setTimeout(() => {
                        Object.keys(this.dataJadwal).forEach(key => {
                            if (
                                (this.componentData.initialData[key] ||
                                    typeof this.componentData.initialData[
                                        key
                                    ] == "boolean") &&
                                key !== "hari"
                            ) {
                                this.dataJadwal[
                                    key
                                ] = this.componentData.initialData[key];
                            } else if (
                                key == "hari" &&
                                this.componentData.initialData.hari
                            ) {
                                this.dataJadwal.hari = this.dayToHari(
                                    this.componentData.initialData.hari
                                );
                            }
                        });
                    }, 50);
                }
                if (this.componentData.isEdit) {
                    this.isEdit = this.componentData.isEdit;
                    this.modalTitle = "Edit Jadwal";
                }
            }
        }
    }
};
</script>

<style></style>
