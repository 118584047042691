<template>
    <div>
        <base-header
            base-title="Dokter Detail"
            class="sticky-base-header"
        ></base-header>
        <DoctorScreen
            :isLoaded="isLoaded"
            :cardData="cardDoctorData"
            :recordValues="recordValues"
            :record-limit.sync="selectedRecord"
            :selectedRecord="selectedRecord"
            :currentPage="currentPage"
            :filterList="filterList"
            :methodObject="methodObject"
            :totalItems="totalItems"
            :isFetching="isFetching"
            :isAsc="isAsc"
            :isRenderComponent="isRenderComponent"
            :permissionObject="permissionObject"
            :isSyncDataLoading="isSyncDataLoading"
            :initialSearch="searchQuery"
            :link-data="linDokter"
        >
        </DoctorScreen>
    </div>
</template>

<script>
import DoctorScreen from "./DoctorsScreen.vue";
import BaseHeader from "../_base/BaseHeader.vue";
import DoctorFilterModal from "./components/DoctorModal/DoctorFilterModal.vue";
import AddDoctorModal from "./components/DoctorModal/AddDoctorModal.vue";

import ModalService from "../../services/modal.service";
import BlockUI from "../_js/BlockUI";

// import MasterDoctorCardRepository from
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import DoctorDataClass from "./class/doctor-data-class";
import PermissionClass from "./class/permission-class";

import HospitalIcon from "../_icons/HospitalIcon.vue";
import HospitalIconSlashed from "../_icons/HospilatIconSlashed.vue";

import Telekonsul from "../_icons/Telekonsul.vue";
import TelekonsulSlashed from "../_icons/TelekonsulSlashed.vue";

import Mobile from "../_icons/Mobile.vue";
import MobileSlashed from "../_icons/MobileSlashed.vue";
import CallFill from "../_icons/CallFill.vue";
import CallSlashed from "../_icons/CallSlashed.vue";

import SwalCustom from "../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

import createRequestOption from "../../repositories/RequestParamUtil";

export default {
    name: "MasterDoctorController",
    components: {
        DoctorScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            masterDoctorRepo: RepositoryFactory.get("doctorCards"),
            PhotoRepository: RepositoryFactory.get("photo"),
            DoctorOldRepository: RepositoryFactory.get('doctor'),
            blockUi: new BlockUI(),
            methodObject: {
                openModalFilterMethod: vx.openModalFilter,
                filterItemsDataMethod: vx.filterItemsData,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                opemModalAddDoctorMethod: vx.opemModalAddDoctor,
                changePageMethod: vx.changePage,
                searchSubmitMethod: vx.changeSeachQuery,
                deleteAllFilterMethod: vx.deleteAllFilter,
                deleteDokterConfirmationMethod: vx.deleteDokterConfirmation,
                refreshDataMethod: vx.refreshData,
                changeSortMethod: vx.changeSort,
                syncDataMethod: vx.syncData,
                linkButton: vx.linkButtonData,
                closeModal: vx.closeModalDokter
            },

            doctorCardChipIcon: {
                konsultasiIcons: [
                    {
                        icon: HospitalIcon,
                        type: "component"
                    },
                    { icon: Telekonsul, type: "component" }
                ],
                bookingIcons: [
                    {
                        icon: Mobile,
                        type: "component"
                    },
                    {
                        icon: CallFill,
                        type: "component"
                    }
                ],
                bahasaIcons: [
                    {
                        icon: "ID",
                        type: "text"
                    },
                    {
                        icon: "EN",
                        type: "text"
                    }
                ]
            },
            recordValues: [10, 25, 50],
            selectedRecord: 10,
            totalItems: 0,
            cardDoctorData: [],
            isAsc: true,
            currentPage: 1,
            filterList: {},
            isLoaded: false,
            isFetching: false,
            mandatoryAddDoctorFieldNames: [
                "nama",
                "gender",
                "spesialisasi",
                "tipeKonsultasi",
                "tarifTelekonsultasi"
            ],
            icons: [
                {
                    icon: HospitalIcon,
                    condition: true,
                    type: "facetoface"
                },
                {
                    icon: HospitalIconSlashed,
                    condition: false,
                    type: "facetoface"
                },
                {
                    icon: Telekonsul,
                    condition: true,
                    type: "telekonsul"
                },
                {
                    icon: TelekonsulSlashed,
                    condition: false,
                    type: "telekonsul"
                },
                {
                    icon: Mobile,
                    condition: true,
                    type: "caredoctor"
                },
                {
                    icon: MobileSlashed,
                    condition: false,
                    type: "caredoctor"
                },
                {
                    icon: CallFill,
                    condition: true,
                    type: "callcenter"
                },
                {
                    icon: CallSlashed,
                    condition: false,
                    type: "callcenter"
                }
            ],
            dropDownData: {
                doctorOptions: [],
                klinikOptions: [],
                spesialisasiOptions: [],
                tipeKonsultasiOptions: [],
                tarifTelekonsultasiOptions: [],
                subSpesialisasiOptions: []
            },
            isSearchApi: false,
            searchQuery: null,
            isRenderComponent: true,
            permissionObject: {},
            isSyncDataLoading: false,
            linDokter: {
                modal: false,
                userId: 0,
                link: ''
            }
        };
    },
    methods: {
        async resetInput() {
            this.isRenderComponent = !this.isRenderComponent;
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    text: messageStr,
                    allowOutsideClick: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        changeSeachQuery(search) {
            this.searchQuery = search ? search.trim() : "";
        },
        async searchSubmit(search) {
            try {
                const page = this.currentPage;
                const page_size = this.selectedRecord;
                let sortStr = this.isAsc ? "asc" : "desc";
                let params = {
                    page,
                    page_size,
                    q: this.searchQuery,
                    sort: sortStr
                };

                // const reqParams = this.filterQuery(params);
                if (this.searchQuery) {
                    this.isSearchApi = true;
                    this.isFetching = true;
                    const resp = await this.masterDoctorRepo.getDoctorSearch(
                        params
                    );
                    this.filterList = {};
                    this.isFetching = false;
                    this.totalItems = +resp.headers["x-count"];

                    const data = DoctorDataClass.dataDoctorCard(
                        resp.data,
                        this.icons
                    );
                    this.cardDoctorData = data;
                } else {
                    this.getDoctorList();
                }
            } catch (error) {
                this.isFetching = false;
                console.log(error, "getDoctorList");
                this.showErrorPopUp(error);
            }
        },
        async changeSort() {
            this.isAsc = !this.isAsc;
            const unblock = this.blockUi.blockPage();
            if (this.isSearchApi) {
                this.searchSubmit(this.searchQuery);
            } else {
                await this.getDoctorList();
            }
            unblock();
        },
        async deleteAllFilter() {
            let unblock;
            this.isSearchApi = false;
            try {
                this.filterList = {};
                unblock = this.blockUi.blockPage();
                await this.getDoctorList();
                unblock();
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error);
            }
        },
        async getDoctorList() {
            try {
                this.isSearchApi = false;
                this.resetInput();
                const page = this.currentPage;
                const page_size = this.selectedRecord;
                let sortStr = this.isAsc ? "asc" : "desc";
                let params = {
                    page,
                    page_size,
                    sort: sortStr
                };

                const reqParams = this.filterQuery(params);
                this.isFetching = true;
                const resp = await this.masterDoctorRepo.getDoctorDataList(
                    reqParams
                );
                this.isFetching = false;

                if (resp.error) {
                    throw resp;
                }
                // console.log(resp, "response 351");
                let totalItems = 0;
                if (resp.headers) {
                    if (resp.headers["x-count"]) {
                        totalItems = +resp.headers["x-count"];
                    }
                }
                this.totalItems = totalItems;
                const data = DoctorDataClass.dataDoctorCard(
                    resp.data,
                    this.icons
                );
                this.cardDoctorData = data;
            } catch (error) {
                this.isFetching = false;
                console.log(error, "getDoctorList");
                this.showErrorPopUp(error);
            }
        },
        filterQuery(params) {
            let requestParam = createRequestOption(params);
            if (this.filterList.selectedDays) {
                if (this.filterList.selectedDays.length > 0) {
                    this.filterList.selectedDays.forEach(day => {
                        requestParam.params.append(
                            "hari",
                            DoctorDataClass.daysToNum(day)
                        );
                    });
                }
            }
            if (this.filterList.selectedSubSpesialisasi) {
                if (this.filterList.selectedSubSpesialisasi.length > 0) {
                    this.filterList.selectedSubSpesialisasi.forEach(subSpe => {
                        requestParam.params.append(
                            "subSpesialisasi",
                            subSpe.id
                        );
                    });
                }
            }
            if (this.filterList.selectedDoctors) {
                if (this.filterList.selectedDoctors.length > 0) {
                    this.filterList.selectedDoctors.forEach(dok => {
                        requestParam.params.append("id", dok.id);
                    });
                }
            }
            if (this.filterList.selectedSpesialisasi) {
                if (this.filterList.selectedSpesialisasi.length > 0) {
                    this.filterList.selectedSpesialisasi.forEach(spe => {
                        requestParam.params.append("spesialisasi", spe.id);
                    });
                }
            }
            if (this.filterList.selectedKlinik) {
                if (this.filterList.selectedKlinik.length > 0) {
                    this.filterList.selectedKlinik.forEach(klinik => {
                        requestParam.params.append("klinik", klinik.id);
                    });
                }
            }
            if (this.filterList.id) {
                requestParam.params.append("id", this.filterList.id);
            }
            if (this.filterList.genderDokter) {
                requestParam.params.append(
                    "jenis_kelamin",
                    this.filterList.genderDokter
                );
            }
            if (this.filterList.jamMulai || this.filterList.jamSelesai) {
                let jamMulai = "00:00";
                let jamSelesai = "00:00";
                let waktu = "";
                if (this.filterList.jamMulai) {
                    jamMulai = this.filterList.jamMulai;
                }
                if (this.filterList.jamSelesai) {
                    jamSelesai = this.filterList.jamSelesai;
                }
                waktu = `${jamMulai}-${jamSelesai}`;
                requestParam.params.append("waktu", waktu);
            }
            if (this.filterList.dateRange) {
                if (this.filterList.dateRange.start) {
                    requestParam.params.append(
                        "tanggalMulai",
                        this.filterList.dateRange.start
                    );
                }
                if (this.filterList.dateRange.end) {
                    requestParam.params.append(
                        "tanggalSelesai",
                        this.filterList.dateRange.end
                    );
                }
            }
            if (this.filterList.tipeBooking) {
                let tatapMuka;
                const str = this.filterList.tipeBooking;
                if (str.includes("tidak")) {
                    tatapMuka = false;
                } else {
                    tatapMuka = true;
                }
                requestParam.params.append("isBookAllow", tatapMuka);
            }

            if (this.filterList.tipeTelekonsul) {
                // let telekonsul;
                const str = this.filterList.tipeTelekonsul;
                if (str.includes("tidak")) {
                    // telekonsul = false;
                    requestParam.params.append("isTatapMuka", true);
                } else {
                    // telekonsul = true;
                    requestParam.params.append("isTeleconsult", true);
                }
            }
            if (this.filterList.publishDokter) {
                // requestParam.params.append("publish", true);
                let publish;
                const str = this.filterList.publishDokter;
                if (str == "publish") {
                    publish = true;
                } else {
                    publish = false;
                }
                requestParam.params.append("isPublish", publish);
            }

            return requestParam;
        },
        async getDoctorDataDropdown(searchQuery) {
            try {
                let params = {
                    page_size: 20
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                    params.page_size = 1000;
                    delete params.page_size;
                }
                const resp = await this.masterDoctorRepo.getDoctorDataDropdown(
                    params
                );
                if (resp.error) {
                    throw resp;
                }
                return resp.data;
            } catch (error) {
                console.log(error, "getDoctorDataDropdown");
                this.showErrorPopUp(error);
            }
        },
        async getKlinikList(searchQuery) {
            try {
                let params = {
                    page_size: 1
                };
                if (searchQuery) {
                    params.namaIndo = searchQuery;
                }
                const response = await this.masterDoctorRepo.getKlinikDataDropDown(
                    params
                );
                if (response.error) {
                    throw resp;
                }
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getKlinikDataDropDown(
                    params
                );
                // this.dropDownData.klinikOptions = DoctorDataClass.klinikDropDown(
                //     resp.data
                // );
                if (resp.error) {
                    throw resp;
                }
                return DoctorDataClass.klinikDropDown(resp.data);
            } catch (error) {
                console.log(error, "getKlinikList");
                this.showErrorPopUp(error);
            }
        },
        async getSpesialisasiList(searchQuery) {
            try {
                let params = {
                    page_size: 1
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                }
                const response = await this.masterDoctorRepo.getSpesialisasiDataDropDown(
                    params
                );
                if (response.error) {
                    throw resp;
                }
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getSpesialisasiDataDropDown(
                    params
                );
                // this.dropDownData.spesialisasiOptions = DoctorDataClass.spesialisasiDropDown(
                //     resp.data
                // );
                if (resp.error) {
                    throw resp;
                }
                return DoctorDataClass.spesialisasiDropDown(resp.data);
            } catch (error) {
                console.log(error, "getSpesialisasiList");
                this.showErrorPopUp(error);
            }
        },
        async getSubSpesialisasiList(speId, searchQuery) {
            try {
                console.log(speId, searchQuery, "speId, searchQuery");
                let params = {
                    page_size: 1,
                    spesialisasi: speId
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                }
                const response = await this.masterDoctorRepo.getSubSpesialisasiDataDropDown(
                    params
                );
                if (response.error) {
                    throw resp;
                }
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getSubSpesialisasiDataDropDown(
                    params
                );
                if (resp.error) {
                    throw resp;
                }
                return DoctorDataClass.subSpesialisasiDropDown(resp.data);
            } catch (error) {
                console.log(error, "getSubSpesialisasiList");
                this.showErrorPopUp(error);
            }
        },
        async getTarifTelekonsultasiList(searchQuery) {
            try {
                let params = {
                    page_size: 1
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                }
                const response = await this.masterDoctorRepo.getTarifTelekonsultasiDataDropDown(
                    params
                );
                if (response.error) {
                    throw resp;
                }
                params.page_size = response.headers["x-count"];
                const resp = await this.masterDoctorRepo.getTarifTelekonsultasiDataDropDown(
                    params
                );
                // this.dropDownData.tarifTelekonsultasiOptions = DoctorDataClass.tarifKonsultasiDropDown(
                //     resp
                // );
                if (resp.error) {
                    throw resp;
                }
                return DoctorDataClass.tarifKonsultasiDropDown(resp.data);
            } catch (error) {
                console.log(error, "getTarifTelekonsultasiList");
                this.showErrorPopUp(error);
            }
        },
        async postDataDoctor(payload) {
            try {
                const resp = await this.masterDoctorRepo.postAddDoctorData(
                    payload
                );
                if (resp.err) {
                    throw resp;
                    // return true;
                }
                return resp;
            } catch (error) {
                // return false;
                console.log(error, "postDataDoctor");
                this.showErrorPopUp(error);
            }
        },
        closeModalDokter (open) {
            this.linDokter.modal = open
        },
         async linkButtonData(id, tenant_id) {
            try {
                // const resp = await this.masterDoctorRepo.postLinkRequestSSO(id, tenant_id);
                // const masterDoctore = await this.DoctorOldRepository.getDoctor(id)
                const allPromise = await Promise.all([this.masterDoctorRepo.postLinkRequestSSO(id, tenant_id), this.DoctorOldRepository.getDoctor(id)])
                                            .catch(err => {
                                                 this.showErrorPopUp(err);
                                                 return false
                                            })
                console.log(allPromise)
                if(allPromise[0] && allPromise[1])
                // if(resp) {
                    this.linDokter.modal = true
                    this.linDokter.link = allPromise[0].data
                    this.linDokter.userId = allPromise[1].data && allPromise[1].data.userId ? allPromise[1].data.userId : 0
                // }
                // this.link
            } catch(error) {
                 this.showErrorPopUp(error);
            }
        },
        changePage(page) {
            this.currentPage = page;
            let unblock = this.blockUi.blockPage();
            if (this.isSearchApi) {
                // (async function(search){
                // await
                this.searchSubmit(this.searchQuery);
                // }(this.searchQuery))
            } else {
                this.getDoctorList();
            }
            unblock();
        },
        filterItemsData() {
            let payload = [];
            for (let key in this.filterList) {
                if (key !== "dateRange") {
                    if (Array.isArray(this.filterList[key])) {
                        this.filterList[key].forEach(el => {
                            if (
                                key == "bahasa" ||
                                key == "genderDokter" ||
                                key == "publishDokter" ||
                                key == "selectedDays"
                            ) {
                                payload.push({
                                    category: key,
                                    value: el
                                });
                            } else {
                                payload.push({
                                    category: key,
                                    value: el.nama
                                });
                            }
                        });
                    } else if (
                        key == "tipeBooking" ||
                        key == "tipeTelekonsul" ||
                        key == "publishDokter" ||
                        key == "genderDokter" ||
                        key == "id"
                    ) {
                        payload.push({
                            category: key,
                            value: this.filterList[key]
                        });
                    } else if (
                        key == "jamAkhir" ||
                        key == "jamMulai" ||
                        key == "durationInMinute"
                    ) {
                        payload.push({
                            category: key,
                            value: this.filterList[key] + " " + key
                        });
                    }
                } else {
                    if (this.filterList.dateRange) {
                        if (this.filterList.dateRange.start) {
                            payload.push({
                                category: key + "_start",
                                value: moment(
                                    this.filterList.dateRange.start
                                ).format("DD/MMM/YYYY")
                            });
                        }
                        if (this.filterList.dateRange.end) {
                            payload.push({
                                category: key + "_end",
                                value: moment(
                                    this.filterList.dateRange.end
                                ).format("DD/MMM/YYYY")
                            });
                        }
                    }
                }
            }
            return payload;
        },
        async doctorConfirmation({ ID, nama }, title, text) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning" style="background: #F3F8FF;">
                        <p>${nama}</p>
                        ${ID ? `<p> ID: ${ID} </p>` : ""}
                     </div>`;
                html += `<p class="sub-delete__text">${text}</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: title,
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async postPhoto(payload) {
            try {
                const response = await this.PhotoRepository.createPhoto(
                    payload
                );
                if (response.status == 500) {
                    throw response;
                }
                return response;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async opemModalAddDoctor(props) {
            let closeModalFunc;
            try {
                let payload = {
                    ...props,
                    action: async doctorData => {
                        try {
                            let {
                                validated,
                                image
                            } = DoctorDataClass.postDataDoctor(doctorData);
                            if (validated) {
                                const confirmResult = await this.doctorConfirmation(
                                    validated,
                                    "Data dokter akan ditambah",
                                    "Apakah Anda yakin ingin menambah data dokter berikut?"
                                );
                                if (confirmResult.value) {
                                    const unblock = this.blockUi.blockModal();
                                    if (image) {
                                        const photo = await this.postPhoto(
                                            image
                                        );
                                        validated.fotoId = photo.data.id;
                                        validated.foto = photo.data.alias
                                    }
                                    const resp = await this.postDataDoctor(
                                        validated
                                    );
                                    unblock();
                                    if (resp.error) {
                                        throw resp;
                                    }
                                    if (typeof closeModalFunc == "function") {
                                        closeModalFunc();
                                    }
                                    this.showPopUp(
                                        "Data dokter berhasil di tambah",
                                        "success",
                                        "successImg"
                                    );
                                    this.currentPage = 1;
                                    const unblockPage = this.blockUi.blockPage();
                                    await this.getDoctorList();
                                    unblockPage();
                                }
                            }
                        } catch (error) {
                            console.log(error, "modal action post dokter");
                            this.showErrorPopUp(error);
                        }
                    },
                    searchTarifKonsultasi: async name => {
                        return await this.getTarifTelekonsultasiList(name);
                    },
                    searchKlinik: async name => {
                        return await this.getKlinikList(name);
                    },
                    searchSpesialisasi: async name => {
                        return await this.getSpesialisasiList(name);
                    },
                    searchSubSpesialisasi: async (speId, name) => {
                        return await this.getSubSpesialisasiList(speId, name);
                    }
                };
                let { close } = ModalService.open(AddDoctorModal, payload);
                closeModalFunc = close;
            } catch (error) {
                console.log(error);
            }
        },
        async openModalFilter(props) {
            let closeModalFunc;
            try {
                let payload = {
                    ...props,
                    initialData: this.filterList,
                    action: async data => {
                        let validatedFilter = this.validateDoctorFilterData(
                            data
                        );
                        this.filterList = validatedFilter;
                        this.currentPage = 1;
                        const unblock = this.blockUi.blockModal();
                        this.getDoctorList();
                        unblock();
                        if (typeof closeModalFunc == "function") {
                            closeModalFunc();
                        }
                    },
                    searchDoctor: async name => {
                        return await this.getDoctorDataDropdown(name);
                    },
                    searchKlinik: async name => {
                        return await this.getKlinikList(name);
                    },
                    searchSpesialisasi: async name => {
                        return await this.getSpesialisasiList(name);
                    },
                    searchSubSpesialisasi: async (speId, name) => {
                        return await this.getSubSpesialisasiList(speId, name);
                    },
                    searchTarifKonsultasi: async name => {
                        return await this.getTarifTelekonsultasiList(name);
                    }
                };
                let { close } = ModalService.open(DoctorFilterModal, payload);
                closeModalFunc = close;
            } catch (error) {
                console.log(error, "error");
                this.showErrorPopUp(error);
            }
        },
        validateDoctorFilterData(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (key == "dateRange") {
                    if (!payload.dateRange.start) {
                        delete payload.dateRange.start;
                    }
                    if (!payload.dateRange.end) {
                        delete payload.dateRange.end;
                    }
                } else if (
                    key == "tipeBooking" ||
                    key == "tipeTelekonsul" ||
                    key == "publishDokter" ||
                    key == "id"
                ) {
                    if (payload[key] === null) {
                        delete payload[key];
                    }
                } else {
                    if (Array.isArray(payload[key])) {
                        if (payload[key].length <= 0) {
                            delete payload[key];
                        }
                    } else if (!payload[key]) {
                        delete payload[key];
                    }
                }
            });
            return payload;
        },
        deleteIndvFIlter(payload) {
            this.resetInput();
            this.isSearchApi = false;
            let { category, value } = payload;
            if (category.includes("dateRange")) {
                // this.filterList.dateRange = null;
                if (category.includes("start")) {
                    this.filterList.dateRange.start = null;
                } else {
                    this.filterList.dateRange.end = null;
                }
            } else {
                if (
                    category == "jamAkhir" ||
                    category == "jamMulai" ||
                    category == "durationInMinute" ||
                    category == "tipeTelekonsul" ||
                    category == "tipeBooking" ||
                    category == "id" || 
                    category == "genderDokter" ||
                    category == "publishDokter" 
                ) {
                    const asArray = Object.entries(this.filterList);

                    const filtered = asArray.filter(([key, v]) => {
                        const replaceKey =
                            category == "tipeTelekonsul" ? key : ` ${key}`;
                        return v != value.replace(replaceKey, "");
                    });

                    const newFIlterList = Object.fromEntries(filtered);
                    this.filterList = newFIlterList;
                } else {
                    this.filterList[category] = this.filterList[
                        category
                    ].filter(item => {
                        if (
                            category == "bahasa" ||
                            category == "selectedDays" ||
                            category == "tipeKonsultasi"
                        ) {
                            return item != value;
                        } else {
                            return item.nama != value;
                        }
                    });
                }
            }
            const unblock = this.blockUi.blockPage();
            this.getDoctorList();
            unblock();
        },
        async deleteConfirmation({ nama, id }, text, title) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                        <p>${nama}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `<p class="sub-delete__text">${text}</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: title,
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result;
            } catch (error) {
                this.showErrorPopUp(error);
            }
        },
        async deleteDokterConfirmation({ id, nama }, text, title) {
            try {
                const result = await this.deleteConfirmation(
                    { id, nama },
                    text,
                    title
                );
                if (result.value) {
                    this.deleteDokter(id);
                }
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteDokter(id) {
            let unblock;
            try {
                unblock = this.blockUi.blockPage();
                const resp = await this.masterDoctorRepo.delDataDoctor(id);
                unblock();
                if (resp.error) {
                    throw resp;
                }
                this.showPopUp(
                    "Data dokter berhasil dihapus",
                    "success",
                    "successImg"
                );
                unblock = this.blockUi.blockPage();
                this.getDoctorList();
                unblock();
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async reindexData() {
            try {
                const resp = await this.masterDoctorRepo.reIndexData();
                if (resp.error) {
                    throw resp;
                }
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async refreshData() {
            // this.reindexData();
            const unblock = this.blockUi.blockPage();
            await this.getDoctorList();
            unblock();
        },
        async syncData() {
            this.isSyncDataLoading = true;
            await this.reindexData();
            this.isSyncDataLoading = false;
        },
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        routeQueryToFilterList() {
            try {
                let filterQueryNames = [
                    "dateRange",
                    "selectedDoctors",
                    "selectedDays",
                    "selectedKlinik",
                    "selectedSpesialisasi",
                    "selectedSubSpesialisasi",
                    "id",
                    "jamAkhir",
                    "jamMulai",
                    "genderDokter",
                    "tipeBooking",
                    "tipeTelekonsul",
                    "publishDokter",
                    "searchQuery"
                ];
                filterQueryNames.forEach(name => {
                    if (this.$route.query[name]) {
                        if (
                            name == "dateRange" ||
                            name == "selectedDoctors" ||
                            name == "selectedDays" ||
                            name == "selectedKlinik" ||
                            name == "selectedSpesialisasi" ||
                            name == "selectedSubSpesialisasi" //object dan array
                        ) {
                            this.filterList[name] = JSON.parse(
                                atob(this.$route.query[name])
                            );
                        } else if (name != "searchQuery") {
                            this.filterList[name] = this.$route.query[name];
                        } else if (name == "searchQuery") {
                            this.searchQuery = this.$route.query[name];
                        }
                    }
                });
            } catch (error) {
                console.log(error, "error decrypting query param");
            }
        },
        filterListToQuery(filterList, searchQuery) {
            let filterObj = {};
            Object.keys(filterList).forEach(filterName => {
                if (
                    filterName == "dateRange" ||
                    filterName == "selectedDoctors" ||
                    filterName == "selectedDays" ||
                    filterName == "selectedKlinik" ||
                    filterName == "selectedSpesialisasi" ||
                    filterName == "selectedSubSpesialisasi"
                ) {
                    //object dan array
                    if (filterList[filterName]) {
                        if (filterName == "dateRange") {
                            if (!_.isEmpty(filterList[filterName])) {
                                filterObj[filterName] = btoa(
                                    JSON.stringify(filterList[filterName])
                                );
                            }
                        } else {
                            if (
                                filterName == "selectedDoctors" ||
                                filterName == "selectedDays" ||
                                filterName == "selectedKlinik" ||
                                filterName == "selectedSpesialisasi" ||
                                filterName == "selectedSubSpesialisasi"
                            ) {
                                if (filterList[filterName].length) {
                                    filterObj[filterName] = btoa(
                                        JSON.stringify(filterList[filterName])
                                    );
                                }
                            } else {
                                filterObj[filterName] = btoa(
                                    JSON.stringify(filterList[filterName])
                                );
                            }
                        }
                    }
                } else {
                    filterObj[filterName] = filterList[filterName];
                }
            });
            return filterObj;
        }
    },
    watch: {
        selectedRecord(val) {
            if (val) {
                const unblock = this.blockUi.blockPage();
                this.currentPage = 1;
                this.getDoctorList();
                unblock();
            }
        },
        searchQuery: {
            handler(newVal) {
                if (newVal) {
                    this.$router.push(
                        {
                            name: "dokter-new",
                            query: {
                                searchQuery: newVal
                            }
                        },
                        () => {
                            this.searchSubmit();
                            // this.emitFilterQuery();
                        }
                    );
                } else {
                    this.$router.push(
                        {
                            name: "dokter-new"
                        },
                        () => {
                            this.searchSubmit();
                            // this.emitFilterQuery();
                        }
                    );
                }
            }
        },
        filterList: {
            handler(val, oldVal) {
                if (!this.searchQuery) {
                    this.$router.push(
                        {
                            name: "dokter-new",
                            query: this.filterListToQuery(
                                this.filterList,
                                this.searchQuery
                            )
                        },
                        () => {
                            // this.routeQueryToFilterList();
                            // this.emitFilterQuery();
                        }
                    );
                }
            },
            deep: true
        }
    },
    async created() {
        this.initPermissionCheck();
        this.routeQueryToFilterList();
        if (!this.searchQuery) {
            await this.getDoctorList();
        } else if (this.searchQuery) {
            this.isFetching = true;
            await this.searchSubmit();
            this.isFetching = false;
        }
        this.isLoaded = true;
    }
};
</script>

<style></style>
