<template>
    <div class="information-chip">
        <p :class="classString" style="margin-top: 14px;"></p>
        <div class="divider-row"></div>
        <div
            style="border:none"
            class="information-chip-content"
            v-for="(icon, index) in icons"
            :key="index"
            :title="titleName(icon.title)"
        >
            <component v-if="icon.type == 'component'" :is="icon.icon">
            </component>
            <p class="information-bahasa" v-else>
                {{ icon.icon }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icons: {
            type: Array,
            default: () => []
        },
        informationTitle: {
            type: String,
            default: ""
        },
        classString: {
            type: String,
            default: ""
        }
    },
    methods: {
        titleName(title) {
            let titleName = "-";
            if (title) {
                titleName = title;
                if (titleName == "facetoface") {
                    titleName = "tatap muka";
                }
                if (titleName == "caredoctor") {
                    titleName = `caredokter`;
                }
            }
            return titleName;
        }
    }
};
</script>

<style></style>
