<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header">
            <h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal" style="position: relative;">
                <div class="form-group">
                    <label class="col-form-label form-label"
                        >Dokter ID:<span style="color:red;"></span
                    ></label>
                    <div>
                        <input
                            v-model="filterData.id"
                            type="number"
                            placeholder="Cari Dokter ID"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label form-label"
                        >Dokter :<span style="color:red;"></span
                    ></label>
                    <div class="">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="filterData.selectedDoctors"
                            :options="doctorOptions"
                            placeholder="Pilih Dokter"
                            :searchable="true"
                            :loading="isDoctorSelectLoading"
                            @search-change="searchDoctor"
                        >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Dokter
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Dokter Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                    </div>
                </div>
                <div class="form-group ">
                    <label class=" col-form-label form-label"
                        >Klinik :<span style="color:red;"></span
                    ></label>
                    <div class="">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="filterData.selectedKlinik"
                            :options="klinikOptions"
                            placeholder="Pilih Klinik"
                            :searchable="true"
                            :loading="isKlinikSelectLoading"
                            @search-change="searchKlinik"
                        >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Klinik
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Klinik Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                    </div>
                </div>
                <div class="form-group ">
                    <label class=" col-form-label form-label"
                        >Spesialisasi :<span style="color:red;"></span
                    ></label>
                    <div class="">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="filterData.selectedSpesialisasi"
                            :options="spesialisasiOptions"
                            placeholder="Pilih Spesialisasi"
                            :searchable="true"
                            :loading="isSpesialisasiSelectLoading"
                            @search-change="searchSpesialisasi"
                        >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Spesialisasi
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Spesialisasi Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Subspesialisasi :<span style="color:red;"></span
                    ></label>
                    <div class="">
                        <multiselect
                            class="holiday-multiselect"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="filterData.selectedSubSpesialisasi"
                            :options="subSpesialisasiOptions"
                            placeholder="Pilih Subspesialisasi"
                            :searchable="true"
                            :loading="isSubspesialisasiSelectLoading"
                            @search-change="searchSubSpesialisasi"
                        >
                            <div slot="beforeList" class="beforeList">
                                <p style="text-align:center;margin-top:10px;">
                                    Ketik Untuk Mencari Subspesialisasi
                                </p>
                            </div>
                            <div slot="noOptions" class="noOptions">
                                <p style="text-align:center;margin-top:10px;">
                                    Subspesialisasi Tidak Ditemukan
                                </p>
                            </div>
                            <div slot="noResult" class="noResult">
                                <p style="text-align:center;margin-top:10px;">
                                    Tidak Ada Hasil
                                </p>
                            </div>
                        </multiselect>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group ">
                    <label class=" col-form-label form-label"
                        >Hari Praktek :<span style="color:red;"></span
                    ></label>
                    <div class="">
                        <multiselect
                            class="holiday-multiselect"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="filterData.selectedDays"
                            :options="daysOptions"
                            placeholder="Pilih Hari"
                            :searchable="true"
                        ></multiselect>
                    </div>
                </div>
                <div class="form-group row form-modal">
                    <div
                        class="form-group col-6"
                        style="border-right: 1px solid #CFDFF5;"
                    >
                        <label class="col-form-label form-label"
                            >Jam Mulai :<span style="color:red;"></span
                        ></label>
                        <div class="">
                            <JadwalTime
                                class="time-custom"
                                :changedValue.sync="filterData.jamMulai"
                                :value="filterData.jamAkhir"
                                :time-id="'jadwal_start_doctor_filter'"
                                place-holder="Jam Mulai"
                                :isNeedValidation="false"
                            ></JadwalTime>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class="col-form-label form-label"
                            >Jam Akhir :<span style="color:red;"></span
                        ></label>
                        <div class="">
                            <JadwalTime
                                class="time-custom"
                                :changedValue.sync="filterData.jamAkhir"
                                :value="filterData.jamAkhir"
                                :time-id="'jadwal_end_doctor_filter'"
                                place-holder="Jam Akhir"
                                :isNeedValidation="false"
                            ></JadwalTime>
                        </div>
                    </div>
                </div>
                <div class="form-group row form-modal">
                    <div
                        class="form-group col-6"
                        style="border-right: 1px solid #CFDFF5;"
                    >
                        <label class="col-form-label"
                            >{{ $t("common.tanggalMulai") }}:</label
                        >
                        <div class=" my-auto">
                            <date-picker
                                class="custom-datepicker"
                                :changedValue.sync="filterData.dateRange.start"
                                :id-date-picker="'jadwal_date_start_filter'"
                                :picker-value="filterData.dateRange.start"
                                place-holder="Tanggal Mulai"
                                :is-validate="false"
                                end-date=""
                            ></date-picker>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label class="col-form-label"
                            >{{ $t("common.tanggalSelesai") }}:</label
                        >
                        <div class=" my-auto">
                            <date-picker
                                class="custom-datepicker"
                                :changedValue.sync="filterData.dateRange.end"
                                :id-date-picker="'jadwal_date_end_filter'"
                                :picker-value="filterData.dateRange.end"
                                place-holder="Tanggal Selesai"
                                :is-validate="false"
                                end-date=""
                            ></date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group row form-modal three-state-container">
                    <div
                        class="form-group row col-6 three-state-container_child"
                    >
                        <div
                            style="border-right: 1px solid #CFDFF5;"
                            class="form-group col-6"
                        >
                            <label class=" col-form-label form-label"
                                >Publish Dokter:<span style="color:red;"></span
                            ></label>
                            <div class="">
                                <div
                                    class="d-flex"
                                    style="justify-content: space-between;"
                                >
                                    <div
                                        class="switch-toggle switch-3 switch-candy"
                                    >
                                        <input
                                            id="on-publish"
                                            name="publish"
                                            type="radio"
                                            checked=""
                                            :value="'publish'"
                                            v-model="filterData.publishDokter"
                                        />
                                        <label for="on-publish" onclick="">
                                            <div
                                                style="width:24px;height:18px;"
                                            >
                                                <EyeIcon
                                                    :strokeColor="
                                                        filterData.publishDokter ===
                                                        'publish'
                                                            ? '#ffffff'
                                                            : `#23415B`
                                                    "
                                                    :fillColor="
                                                        filterData.publishDokter ===
                                                        'publish'
                                                            ? '#23415B'
                                                            : `#ffffff`
                                                    "
                                                />
                                            </div>
                                        </label>

                                        <input
                                            id="na-publish"
                                            name="napublish"
                                            type="radio"
                                            checked="checked"
                                            :value="null"
                                            v-model="filterData.publishDokter"
                                        />
                                        <label
                                            for="na-publish"
                                            class="disabled"
                                            onclick=""
                                        >
                                            <div
                                                class="na-switch"
                                                :style="
                                                    filterData.publishDokter ===
                                                    null
                                                        ? ''
                                                        : 'color:#23415B'
                                                "
                                            >
                                                ALL
                                            </div>
                                        </label>

                                        <input
                                            id="off-unpublish"
                                            name="unpublish"
                                            type="radio"
                                            :value="'unpublish'"
                                            v-model="filterData.publishDokter"
                                        />
                                        <label for="off-unpublish" onclick="">
                                            <div
                                                style="width:24px;height:18px;"
                                            >
                                                <EyeIconSlashed
                                                    :strokeColor="
                                                        filterData.publishDokter ===
                                                        'unpublish'
                                                            ? '#ffffff'
                                                            : `#23415B`
                                                    "
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style="border-right: 1px solid #CFDFF5;"
                            class="form-group col-6"
                        >
                            <label class="col-form-label form-label"
                                >Tipe Booking</label
                            >
                            <div class="" style="padding-right: 0;">
                                <div
                                    class="switch-toggle switch-3 switch-candy"
                                >
                                    <input
                                        id="on-booking"
                                        name="booking"
                                        type="radio"
                                        checked=""
                                        :value="'booking'"
                                        v-model="filterData.tipeBooking"
                                    />
                                    <label for="on-booking" onclick="">
                                        <div style="width:24px;height:18px;">
                                            <Mobile
                                                :strokeColor="
                                                    filterData.tipeBooking ==
                                                    'booking'
                                                        ? '#ffffff'
                                                        : `#23415B`
                                                "
                                                :fillColor="
                                                    filterData.tipeBooking ==
                                                    'booking'
                                                        ? '#23415B'
                                                        : `#ffffff`
                                                "
                                            />
                                        </div>
                                    </label>

                                    <input
                                        id="na-booking"
                                        name="booking"
                                        type="radio"
                                        checked="checked"
                                        :value="null"
                                        v-model="filterData.tipeBooking"
                                    />
                                    <label
                                        for="na-booking"
                                        class="disabled"
                                        onclick=""
                                    >
                                        <div
                                            class="na-switch"
                                            :style="
                                                filterData.tipeBooking === null
                                                    ? ''
                                                    : 'color:#23415B'
                                            "
                                        >
                                            ALL
                                        </div>
                                    </label>

                                    <input
                                        id="off-booking"
                                        name="booking"
                                        type="radio"
                                        :value="'tidak-booking'"
                                        v-model="filterData.tipeBooking"
                                    />
                                    <label for="off-booking" onclick="">
                                        <div style="width:24px;height:18px;">
                                            <MobileSlashed
                                                :strokeColor="
                                                    filterData.tipeBooking ==
                                                    'tidak-booking'
                                                        ? '#ffffff'
                                                        : `#23415B`
                                                "
                                            />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group row col-6 three-state-container_child"
                    >
                        <div
                            style="border-right: 1px solid #CFDFF5;"
                            class="form-group col-6"
                        >
                            <label class=" col-form-label form-label"
                                >Tipe Konsultasi:<span style="color:red;"></span
                            ></label>
                            <div class="" style="padding-right: 0;">
                                <div
                                    class="d-flex"
                                    style="justify-content: space-between;"
                                >
                                    <div
                                        class="switch-toggle switch-3 switch-candy"
                                    >
                                        <input
                                            id="on-telekonsul"
                                            name="telekonsul"
                                            type="radio"
                                            checked=""
                                            :value="'telekonsul'"
                                            v-model="filterData.tipeTelekonsul"
                                        />
                                        <label for="on-telekonsul" onclick="">
                                            <div
                                                style="width:24px;height:18px;"
                                            >
                                                <Telekonsul
                                                    :strokeColor="
                                                        filterData.tipeTelekonsul ===
                                                        'telekonsul'
                                                            ? '#ffffff'
                                                            : `#23415B`
                                                    "
                                                    :fillColor="
                                                        filterData.tipeTelekonsul ===
                                                        'telekonsul'
                                                            ? '#23415B'
                                                            : `#ffffff`
                                                    "
                                                />
                                            </div>
                                        </label>

                                        <input
                                            id="na-telekonsul"
                                            name="telekonsul"
                                            type="radio"
                                            checked="checked"
                                            :value="null"
                                            v-model="filterData.tipeTelekonsul"
                                        />
                                        <label
                                            for="na-telekonsul"
                                            class="disabled"
                                            onclick=""
                                        >
                                            <div
                                                class="na-switch"
                                                :style="
                                                    filterData.tipeTelekonsul ===
                                                    null
                                                        ? ''
                                                        : 'color:#23415B'
                                                "
                                            >
                                                ALL
                                            </div>
                                        </label>

                                        <input
                                            id="off-telekonsul"
                                            name="telekonsul"
                                            type="radio"
                                            :value="'tidak-telekonsul'"
                                            v-model="filterData.tipeTelekonsul"
                                        />
                                        <label for="off-telekonsul" onclick="">
                                            <div
                                                style="width:24px;height:18px;"
                                            >
                                                <TelekonsulSlashed
                                                    :strokeColor="
                                                        filterData.tipeTelekonsul ===
                                                        'tidak-telekonsul'
                                                            ? '#ffffff'
                                                            : `#23415B`
                                                    "
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label class="col-form-label form-label"
                                >Gender:<span style="color:red;"></span
                            ></label>
                            <div class="">
                                <div
                                    class="d-flex"
                                    style="justify-content: space-between;"
                                >
                                    <div
                                        class="switch-toggle switch-3 switch-candy"
                                    >
                                        <input
                                            id="on-gender"
                                            name="gender"
                                            type="radio"
                                            checked=""
                                            :value="'MALE'"
                                            v-model="filterData.genderDokter"
                                        />
                                        <label for="on-gender" onclick="">
                                            <div
                                                class="na-switch"
                                                style="width:24px;height:18px;"
                                            >
                                                <Men
                                                    :strokeColor="
                                                        filterData.genderDokter !==
                                                        'MALE'
                                                            ? '#23415B'
                                                            : '#FFFFFF'
                                                    "
                                                >
                                                </Men>
                                            </div>
                                        </label>

                                        <input
                                            id="na-gender"
                                            name="gender"
                                            type="radio"
                                            checked="checked"
                                            :value="null"
                                            v-model="filterData.genderDokter"
                                        />
                                        <label
                                            for="na-gender"
                                            class="disabled"
                                            onclick=""
                                        >
                                            <div
                                                class="na-switch"
                                                :style="
                                                    filterData.genderDokter ===
                                                    null
                                                        ? ''
                                                        : 'color:#23415B'
                                                "
                                            >
                                                ALL
                                            </div>
                                        </label>

                                        <input
                                            id="off-gender"
                                            name="gender"
                                            type="radio"
                                            :value="'FEMALE'"
                                            v-model="filterData.genderDokter"
                                        />
                                        <label for="off-gender" onclick="">
                                            <div
                                                class="na-switch"
                                                style="width:24px;height:18px;"
                                            >
                                                <Women
                                                    :strokeColor="
                                                        filterData.genderDokter !==
                                                        'FEMALE'
                                                            ? '#23415B'
                                                            : '#FFFFFF'
                                                    "
                                                >
                                                </Women>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalBody>
        <ModalFooter class="custom-footer">
            <div>
                <button
                    @click.prevent="buttonSimpanClicked"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import JadwalTime from "../../../_select/JadwalTime.vue";
import VueInputNumber from "../../../_general/VueInputNumber.vue";
import DatePicker from "../../../_general/DatePicker.vue";
import Multiselect from "vue-multiselect";

import EyeIcon from "../../../_icons/EyeIcon.vue";
import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";

import HospitalIcon from "../../../_icons/HospitalIcon.vue";
import HospilatIconSlashed from "../../../_icons/HospilatIconSlashed.vue";
import HospitalIconWhite from "../../../_icons/HospitalIconWhite.vue";

import Telekonsul from "../../../_icons/Telekonsul.vue";
import TelekonsulWhite from "../../../_icons/TelekonsulWhite.vue";
import TelekonsulSlashed from "../../../_icons/TelekonsulSlashed.vue";

import Mobile from "../../../_icons/Mobile.vue";
import MobileSlashed from "../../../_icons/MobileSlashed.vue";

import Men from "../../../_icons/GenderMen.vue";
import Women from "../../../_icons/GenderWomen.vue";

// import FormModalHoliday from "../FormModalHoliday.vue";

// import DateRangePicker from "../../../_general/CustomDateRangePicker.vue";

export default {
    name: "DoctorFilterModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        // DateRangePicker,
        Multiselect,
        DatePicker,
        JadwalTime,
        VueInputNumber,

        EyeIcon,
        EyeIconSlashed,
        HospitalIcon,
        Telekonsul,
        HospilatIconSlashed,
        HospitalIconWhite,
        TelekonsulWhite,
        TelekonsulSlashed,
        MobileSlashed,
        Mobile,
        Men,
        Women
        // FormModalHoliday
    },
    mixins: [ModalMixin],
    data() {
        var vx = this;
        return {
            modalTitle: "Doctor Filter",
            initialDateRange: { start: "", end: "" },
            doctorOptions: [],
            klinikOptions: [],
            spesialisasiOptions: [],
            subSpesialisasiOptions: [],
            daysOptions: [
                "Senin",
                "Selasa",
                "Rabu",
                "Kamis",
                "Jumat",
                "Sabtu",
                "Minggu"
            ],
            filterData: {
                id: null,
                selectedDays: [],
                selectedSubSpesialisasi: [],
                selectedDoctors: [],
                selectedSpesialisasi: [],
                selectedKlinik: [],
                jamMulai: "",
                jamAkhir: "",
                dateRange: {
                    start: null,
                    end: null
                },
                // durationInMinute: 0,
                publishDokter: null,
                tipeBooking: null,
                tipeTelekonsul: null,
                genderDokter: null
                // bahasa: []
            },
            isDoctorSelectLoading: false,
            isKlinikSelectLoading: false,
            isSpesialisasiSelectLoading: false,
            isSubspesialisasiSelectLoading: false,
            searchDoctor: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor(
                                    query
                                );
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor();
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isDoctorSelectLoading = false;
                    console.log(error);
                }
            }, 500),
            searchKlinik: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchKlinik ==
                                "function"
                            ) {
                                vx.isKlinikSelectLoading = true;
                                const resp = await vx.componentData.searchKlinik(
                                    query
                                );
                                vx.isKlinikSelectLoading = false;
                                vx.klinikOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchKlinik ==
                                "function"
                            ) {
                                vx.isKlinikSelectLoading = true;
                                const resp = await vx.componentData.searchKlinik();
                                vx.isKlinikSelectLoading = false;
                                vx.klinikOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isKlinikSelectLoading = false;
                    console.log(error);
                }
            }, 1000),
            searchSpesialisasi: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSpesialisasi ==
                                "function"
                            ) {
                                vx.isSpesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSpesialisasi(
                                    query
                                );
                                vx.isSpesialisasiSelectLoading = false;
                                vx.spesialisasiOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSpesialisasi ==
                                "function"
                            ) {
                                vx.isSpesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSpesialisasi();
                                vx.isSpesialisasiSelectLoading = false;
                                vx.spesialisasiOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isSpesialisasiSelectLoading = false;
                    console.log(error);
                }
            }, 1000),
            searchSubSpesialisasi: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSubSpesialisasi ==
                                "function"
                            ) {
                                vx.isSubspesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSubSpesialisasi(
                                    query
                                );
                                vx.isSubspesialisasiSelectLoading = false;
                                vx.subSpesialisasiOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSubSpesialisasi ==
                                "function"
                            ) {
                                vx.isSubspesialisasiSelectLoading = true;
                                const resp = await vx.componentData.searchSubSpesialisasi();
                                vx.isSubspesialisasiSelectLoading = false;
                                vx.subSpesialisasiOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isSubspesialisasiSelectLoading = false;
                    console.log(error);
                }
            }, 1000)
        };
    },
    methods: {
        buttonSimpanClicked() {
            // console.log(this.dateRange, this.holidayType, this.selectedDoctors);
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    let payload = this.filterData;
                    this.componentData.action(payload);
                }
            }
        },
        initFilterData() {
            const filterListNames = [
                "id",
                "jamMulai",
                "jamAkhir",
                "publishDokter",
                "tipeTelekonsul",
                "tipeBooking",
                "genderDokter",
                "selectedDays",
                "dateRange",
                "selectedSubSpesialisasi",
                "selectedDoctors",
                "selectedSpesialisasi",
                "selectedKlinik"
            ];
            if (this.componentData.initialData) {
                if (this.componentData.initialData.dateRange) {
                    if (this.componentData.initialData.dateRange.start) {
                        this.filterData.dateRange.start = this.componentData.initialData.dateRange.start;
                    }
                    if (this.componentData.initialData.dateRange.end) {
                        this.filterData.dateRange.end = this.componentData.initialData.dateRange.end;
                    }
                }
                filterListNames.forEach(key => {
                    if (this.componentData.initialData[key]) {
                        this.filterData[key] = this.componentData.initialData[
                            key
                        ];
                    }
                });
            }
        }
    },
    created() {},
    mounted() {
        this.initFilterData();
        this.searchDoctor();
        this.searchKlinik();
        this.searchSpesialisasi();
        this.searchSubSpesialisasi();
        let DatePickers = document.querySelectorAll(".daterangepicker");
        DatePickers.forEach(el => {
            el.style.zIndex = "1055";
        });
    }
};
</script>

<style></style>
