<template>
    <div class="doctors-card" style="position:relative;">
        <div class="doctors-content">
            <div class="doctors-profile">
                <DoctorProfile
                    :tglLahir="doctorCardData.tglLahir"
                    :gender="doctorCardData.gender"
                    :avatar="doctorCardData.avatar"
                />
            </div>
            <div class="doctors-information">
                <DoctorInformation
                    :bookingIcons="bookingIcons"
                    :konsultasiIcons="konsultasiIcons"
                    :doctorId="doctorCardData.doctorId"
                    :doctorName="doctorCardData.doctorName"
                    :klinik="doctorCardData.klinik"
                    :spesialisasi="doctorCardData.spesialisasi"
                    :subSpesialisasi="doctorCardData.subSpesialisasi"
                    :isProfilePublished="doctorCardData.isProfilePublished"
                />
                <slot name="extra-info"></slot>
            </div>
            <slot name="action-button"></slot>
        </div>
        <div class="doctor-spesialisasi-mobile">
            <div class="form-divider"></div>
            <div class="d-flex">
                <div
                    class="mobile-margin"
                    style="display: flex;flex-direction: column;"
                >
                    <label class="doctor-card-label">Klinik</label>
                    <label class="doctor-card-label">Spesialisasi</label>
                    <label class="doctor-card-label">
                        Subspesialisasi
                    </label>
                </div>
                <div
                    class="mobile-margin"
                    style="display: flex;flex-direction: column;margin-left: 20px;"
                >
                    <div class="d-flex mobile-horizon-scroll">
                        <p
                            class="plain-text"
                            v-for="(text, index) in doctorCardData.klinik"
                            :key="index"
                        >
                            {{ text }}
                        </p>
                        <p
                            class="plain-text"
                            v-if="doctorCardData.klinik.length == 0"
                        >
                            -
                        </p>
                    </div>
                    <div class="d-flex mobile-horizon-scroll">
                        <PublishChip
                            v-for="(spe, index) in doctorCardData.spesialisasi"
                            :key="index"
                            :textString="spe.nama"
                            :isPublished="spe.isActive"
                        ></PublishChip>
                        <p
                            class="plain-text"
                            v-if="doctorCardData.spesialisasi.length == 0"
                        >
                            -
                        </p>
                    </div>
                    <div class="d-flex mobile-horizon-scroll">
                        <PublishChip
                            v-for="(sub,
                            index) in doctorCardData.subSpesialisasi"
                            :key="index"
                            :textString="sub.nama"
                            :isPublished="sub.isActive"
                        ></PublishChip>
                        <p
                            class="plain-text"
                            v-if="doctorCardData.subSpesialisasi.length == 0"
                        >
                            -
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <slot
            name="card-footer"
            :jadwal="doctorCardData.jadwalDatas"
            :activeDays="doctorCardData.activeDays"
        ></slot>
        <div class="half-card"></div>
    </div>
</template>

<script>
import TrashIcon from "../../_icons/TrashIcon.vue";
import EditFillIcon from "../../_icons/EditFillIcon.vue";
import CopyIcon from "../../_icons/CopyIcon.vue";
import EllipsisV from "../../_icons/EllipsisV.vue";
import EyeIcon from "../../_icons/EyeIcon.vue";

import PublishChip from "./PublishChips.vue";
import InformationChips from "./InformationChips.vue";
import DoctorProfile from "./DoctorProfile.vue";
import DoctorInformation from "./DoctorInformation.vue";

import dummyImage from "../assets/image58.png";
export default {
    name: "DoctorCard",
    components: {
        TrashIcon,
        EditFillIcon,
        CopyIcon,
        PublishChip,
        InformationChips,
        DoctorProfile,
        DoctorInformation,
        EllipsisV,
        EyeIcon
    },
    filters: {
        dateFormat(date) {
            return moment(date).format("DD/MM/YYYY");
        }
    },
    data() {
        return {
            image: dummyImage
        };
    },
    props: {
        konsultasiIcons: {
            type: Array
        },
        bookingIcons: {
            type: Array
        },
        doctorCardData: {
            type: Object
        }
    }
};
</script>

<style></style>
