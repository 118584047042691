<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5 5.5L11 8L14.5 10.5V5.5Z"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 4.5H4.5C3.94772 4.5 3.5 4.94772 3.5 5.5V10.5C3.5 11.0523 3.94772 11.5 4.5 11.5H10C10.5523 11.5 11 11.0523 11 10.5V5.5C11 4.94772 10.5523 4.5 10 4.5Z"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M2 13L14 2" :stroke="strokeColor" stroke-linecap="round" />
    </svg>
</template>
<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>
