<template>
    <div>
        <div class="d-flex flex-wrap">
            <label
                class="btn btn-elevate btn-secondary btn-pill btn-sm mr-2"
                style="color: #5867dd; 
                    background-color: #fff;
                    border-color: #5867dd;"
                :class="{
                    'btn-outline-primary': true,
                    active: cekTrue(index),
                    disabled: cekTrue(index)
                }"
                v-for="(slot, index) in listJadwals"
                :key="index"
            >
                <input
                    type="radio"
                    name="action"
                    class="d-none"
                    :value="slot"
                    @click="masuk(slot, index)"
                />
                {{ slot.jamMulai }} - {{ slot.jamSelesai }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            reservasi: [],
            listDuration: [
                {
                    id: 10523,
                    duration: 10
                },
                {
                    id: 10521,
                    duration: 15
                },
                {
                    id: 10519,
                    duration: 20
                },
                {
                    id: 10522,
                    duration: 30
                },
                {
                    id: 10520,
                    duration: 50
                }
            ]
        };
    },
    model: {
        prop: "indexes",
        event: "change"
    },
    props: {
        listJadwals: { type: Array },
        indexes: Array
    },
    methods: {
        masuk(val, index) {
            const found = this.indexes.find(el => el == index);

            if (found !== undefined) {
                let ind = this.indexes.indexOf(index);
                this.indexes.splice(ind, 1);
            } else {
                this.indexes.push(index);
            }
        },
        cekTrue(index) {
            if (this.indexes) {
                const found = this.indexes.find(el => el == index);
                return found !== undefined ? true : false;
            }

            return false;
        }
    },
    indexes: {
        get() {
            return this.indexes;
        },
        set(newValue) {
            this.$emit("change", newValue);
        }
    }
    // watch:  {
    //     listJadwals: function(){
    //         this.reservasi = []
    //         this.indexes = []
    //     }
    // },
};
</script>

<style></style>
