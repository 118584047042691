<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.6665 13.3333H3.33316C3.15635 13.3333 2.98678 13.4036 2.86176 13.5286C2.73674 13.6536 2.6665 13.8232 2.6665 14C2.6665 14.1768 2.73674 14.3464 2.86176 14.4714C2.98678 14.5964 3.15635 14.6667 3.33316 14.6667H12.6665C12.8433 14.6667 13.0129 14.5964 13.1379 14.4714C13.2629 14.3464 13.3332 14.1768 13.3332 14C13.3332 13.8232 13.2629 13.6536 13.1379 13.5286C13.0129 13.4036 12.8433 13.3333 12.6665 13.3333ZM3.33316 12H3.39317L6.17317 11.7467C6.4777 11.7163 6.76252 11.5821 6.97983 11.3667L12.9798 5.36665C13.2127 5.12063 13.3386 4.79233 13.3298 4.45368C13.3211 4.11503 13.1784 3.79366 12.9332 3.55999L11.1065 1.73332C10.8681 1.50938 10.5557 1.38089 10.2287 1.37229C9.90175 1.36368 9.58302 1.47557 9.33317 1.68665L3.33316 7.68665C3.11768 7.90396 2.9835 8.18879 2.95316 8.49332L2.6665 11.2733C2.65752 11.371 2.67019 11.4694 2.70361 11.5616C2.73702 11.6538 2.79037 11.7374 2.85983 11.8067C2.92212 11.8684 2.996 11.9173 3.07723 11.9505C3.15845 11.9837 3.24543 12.0005 3.33316 12ZM10.1798 2.66665L11.9998 4.48665L10.6665 5.78665L8.87983 3.99999L10.1798 2.66665Z"
            :fill="strokeColor"
        />
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>
