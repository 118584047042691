<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.2 6C5.27956 6 5.35587 5.96839 5.41213 5.91213C5.46839 5.85587 5.5 5.77956 5.5 5.7V3.8C5.5 3.72044 5.53161 3.64413 5.58787 3.58787C5.64413 3.53161 5.72044 3.5 5.8 3.5H10.2C10.2394 3.5 10.2784 3.50776 10.3148 3.52284C10.3512 3.53791 10.3843 3.56001 10.4121 3.58787C10.44 3.61573 10.4621 3.6488 10.4772 3.68519C10.4922 3.72159 10.5 3.7606 10.5 3.8V5.7C10.5 5.77956 10.5316 5.85587 10.5879 5.91213C10.6441 5.96839 10.7204 6 10.8 6H11.7C11.7394 6 11.7784 6.00776 11.8148 6.02284C11.8512 6.03791 11.8843 6.06001 11.9121 6.08787C11.94 6.11573 11.9621 6.1488 11.9772 6.18519C11.9922 6.22159 12 6.2606 12 6.3V12.2C12 12.2394 11.9922 12.2784 11.9772 12.3148C11.9621 12.3512 11.94 12.3843 11.9121 12.4121C11.8843 12.44 11.8512 12.4621 11.8148 12.4772C11.7784 12.4922 11.7394 12.5 11.7 12.5H4.3C4.22044 12.5 4.14413 12.4684 4.08787 12.4121C4.03161 12.3559 4 12.2796 4 12.2V6.3C4 6.22044 4.03161 6.14413 4.08787 6.08787C4.14413 6.03161 4.22044 6 4.3 6H5.2V6Z"
            :stroke="strokeColor"
        />
        <path
            d="M8 10.505L8.005 10.4995M6.996 6H7.996H6.996ZM8.996 6H7.996H8.996ZM7.996 6V5V6ZM7.996 6V7V6ZM10 10.505L10.005 10.4995L10 10.505ZM10 8.505L10.005 8.4995L10 8.505ZM8 8.505L8.005 8.4995L8 8.505ZM6 8.505L6.005 8.4995L6 8.505ZM6 10.505L6.005 10.4995L6 10.505Z"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>
